
var EnvName = 'live';
var key = {};

if (EnvName == "live") {
    key.FRONT_URL = 'https://swipechain.trade/'
    key.BACK_URL = 'https://api.swipechain.trade/'
    key.secretOrKey = 'yaz'
    key.SOCKET_URL = 'https://api.swipechain.trade/'
    key.Image_URL = "'https://api.swipechain.trade/"
    // key.BACK_URL        =   'http://192.53.121.26:3331/v1/front'
    key.ADMIN_URL        =   'https://contorls.swipechain.trade/'
}
else if (EnvName == 'demo') {
    key.FRONT_URL = 'https://swipe-arbitrage.maticz.in/'
    key.BACK_URL = 'https://backend-swipearbitrage.maticz.in/'
    key.SOCKET_URL = 'https://backend-swipearbitrage.maticz.in/'
    key.secretOrKey = 'yaz'
    key.Image_URL = "https://backend-swipearbitrage.maticz.in/"
    // key.Image_URL = "http://localhost:2053/"
    key.ADMIN_URL = 'https://swipe-arbitrageadmin.maticz.in/'
}
else {
    // http://200.140.70.93:3000
    key.FRONT_URL ='http://200.140.70.93:3000' //'http://localhost:3000'
    key.BACK_URL = 'http://localhost:2078' //'http://200.140.70.93:2078'//'http://localhost:2078'
    key.SOCKET_URL ='http://200.140.70.93:2078'// 'http://localhost:2078'
    key.secretOrKey = 'yaz'
    // key.Image_URL = "http://localhost:2053/"
    // key.Image_URL = "http://localhost:2053/"
    // key.ADMIN_URL        =   'http://192.53.121.26:3331/v1/admin'
}
export const PROJECT_ID = 'b8a1daa2dd22335a2fe1d2e139980ae0';
export const CRYPTOCOMPARE = '03018fc2ff4df50c72ba3114a9735036ef486c7c85bec47b5796961ec976d54e' 
// export const BUYCONTRACTADDRESS =   '0x8876541A4D0351CEf8A557fC06aD16778DF8b2d5' //"0xf4e912869FA9b8709df80AB35d7f9C2A70a34F44" //testnet
export const BUYCONTRACTADDRESS ="0x9a78291429d7aFB749a723bA0147815E55124DdC" //"0x1DD2B0742806d20d7454c071a4142694762DC549"  // "0x4fEaE5448daa85d2Fae555f349AFD2fE2Ba7d8EE" testnet

export const Tokens  = [
    'USDT',
    'USDC',
    'DAI',
    'BUSD'
]
export const CHAINS = [
    // {
    //     NAME: "BSC-TEST",
    //     RPC_URL: "https://data-seed-prebsc-1-s1.binance.org:8545/", // BSC TESTNET
    //     CHAIN_ID: 97,
    //     ROUTER: "0x1F0929D79d2e992C31dD70A637C33e4a8440d3b0",
    //     USDT :"0x0A1F4c4E14Dd83018942c5813768A8bf70b78599",
    //     USDC : "0x0A1F4c4E14Dd83018942c5813768A8bf70b78599",
    //     DAI:"0xeDDC82488ecf00aC8CD8aD942AB3632E41424820",
    //     BUYCONTRACTADDRESS : "0xA13A80c68eBE9f7069B3D680B642C475Db843b70",
    //     TOKENADDRESS: "0x3F1c44C8b7e672674Ee9faE3B96cB3B14F034d72", // DITMAX Token
    //     // MASTERCHEF: "0xA1Ad9d32096693f66091e02c7155E09E84F8f70f",
    //     MASTERCHEF: "0x62404d901467494eC5995CD279b7F8b30AfFf751",
    //     NATIVE: "0x00A0aa653F4230075cE6e9f116188ac0fb36637B"
    // },
    {
        NAME: "BNB Smart Chain Mainnet",
        RPC_URL:"https://bsc-dataseed1.binance.org/",
        CHAIN_ID:56,
        ROUTER:"0x016073980B9C355Ab36de19E4b4030A1c9409603", //"0x29035dEbC68181B33183E3A7fb9291bC2815644F", //pancake
        // ROUTER: "0x2a09fba034e26852ff38d5393f3fd15748518791"
        BNB : "0x0000000000000000000000000000000000000000",
        BUSD : "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
        USDT : "0x55d398326f99059fF775485246999027B3197955",
        USDC : "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
        DAI:"0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3",
    },
    // {
    //     NAME: "TRON-MANI",
    //     RPC_URL: "https://data-seed-prebsc-1-s1.binance.org:8545/", // BSC TESTNET
    //     CHAIN_ID: "0x2b6653dc",
    //     ROUTER: "0x1F0929D79d2e992C31dD70A637C33e4a8440d3b0",
    //     TOKENADDRESS: "0x3F1c44C8b7e672674Ee9faE3B96cB3B14F034d72", // DITMAX Token
    //     // MASTERCHEF: "0xA1Ad9d32096693f66091e02c7155E09E84F8f70f",
    //     MASTERCHEF: "0x62404d901467494eC5995CD279b7F8b30AfFf751",
    //     NATIVE: "0x00A0aa653F4230075cE6e9f116188ac0fb36637B"
    // },
    // {
    //     NAME: "TRON-SHASTA",
    //     RPC_URL: "https://data-seed-prebsc-1-s1.binance.org:8545/", // BSC TESTNET
    //     CHAIN_ID: "0x94a9059e",
    //     ROUTER: "0x1F0929D79d2e992C31dD70A637C33e4a8440d3b0",
    //     TOKENADDRESS: "0x3F1c44C8b7e672674Ee9faE3B96cB3B14F034d72", // DITMAX Token
    //     // MASTERCHEF: "0xA1Ad9d32096693f66091e02c7155E09E84F8f70f",
    //     MASTERCHEF: "0x62404d901467494eC5995CD279b7F8b30AfFf751",
    //     NATIVE: "0x00A0aa653F4230075cE6e9f116188ac0fb36637B"
    // },
    // {
    //     NAME: "TRON-NILE",
    //     RPC_URL: "https://nile.trongrid.io/jsonrpc/", // BSC TESTNET
    //     CHAIN_ID: "0xcd8690dc",
    //     ROUTER: "0x1F0929D79d2e992C31dD70A637C33e4a8440d3b0",
    //     TOKENADDRESS: "0x3F1c44C8b7e672674Ee9faE3B96cB3B14F034d72", // DITMAX Token
    //     // MASTERCHEF: "0xA1Ad9d32096693f66091e02c7155E09E84F8f70f",
    //     MASTERCHEF: "0x62404d901467494eC5995CD279b7F8b30AfFf751",
    //     NATIVE: "0x00A0aa653F4230075cE6e9f116188ac0fb36637B"
    // }
    // {
    //   NAME: "AVALANCHE",
    //   RPC_URL: "https://api.avax-test.network/ext/bc/C/rpc", // ETH TESTNET
    //   CHAIN_ID: 43113,
    //   ROUTER: "0x1F0929D79d2e992C31dD70A637C33e4a8440d3b0",
    //   TOKENADDRESS: "0x3F1c44C8b7e672674Ee9faE3B96cB3B14F034d72", // DITMAX Token
    //   MASTERCHEF: "0xA1Ad9d32096693f66091e02c7155E09E84F8f70f",

    //   NATIVE: "0x00A0aa653F4230075cE6e9f116188ac0fb36637B"
    // },
    //   {
    //     NAME: "Polygon-TEST",
    //     RPC_URL:"https://matic-mumbai.chainstacklabs.com",
    //     CHAIN_ID:80001, 
    //     TOKENADDRESS: "0x9433f3BaAE2984C6F5C8DE6989aB6dee23eaEE17",
    //      MASTERCHEF: "0x74962D8c90dCb1e4761DF94115BE449044179e5A", // new 
    //     // MASTERCHEF: "0x1afd408a544e901af55cB8735d8c6B49c47A11b2",//old
    //     NATIVE: "0x00A0aa653F4230075cE6e9f116188ac0fb36637B"
    // },
    //   {
    //     NAME: "BSC-MAINNET",
    //     RPC_URL:"https://bsc-dataseed1.binance.org/",
    //     CHAIN_ID:56,
    //     TOKENADDRESS: "0x9eeB6c5ff183E6001c65a12D70026b900AE76781",
    //     MASTERCHEF: "0x825A21c5A32d22b1241C7672EC9D04025960f766", 
    //     NATIVE: "0x00A0aa653F4230075cE6e9f116188ac0fb36637B"
    // },
    // {
    //     NAME: "BSC-MAINNET",
    //     RPC_URL: "https://bsc-dataseed1.binance.org/",
    //     CHAIN_ID: 56,
    //     TOKENADDRESS: "0x9eeB6c5ff183E6001c65a12D70026b900AE76781", //irea
    //     MASTERCHEF: "0x5fb7846a3fD122De5feb59bb1Ef67F742a6804d8",
    //     NATIVE: "0x00A0aa653F4230075cE6e9f116188ac0fb36637B",
    //     USDT: "0x55d398326f99059ff775485246999027b3197955",
    //     USDI: "0x91c7eBbDcbdE50559FDA424dd7Dc9AF142B9Ed58"
    // },

];

export { key };
