// Current User
export const profile_Data = 'profile_Data';

export const ISAUTH = 'ISAUTH'
export const WALLET_ADDRESS = 'WALLET_ADDRESS';
export const WALLET_DATA = 'WALLET_DATA';
export const SET_THEME = 'SET_THEME';
export const USER_PLAN = 'USER_PLAN'
export const REFERENCE_CODE = 'REFERENCE_CODE';

export const NOTICE_DATA='NOTICE_DATA'
export const PLANS ="PLANS"
export const CONTRACT_PLANS ="CONTRACT_PLANS"
export const CURRENCY_DATA = 'CURRENCY_DATA';
