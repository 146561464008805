// import { MASTERCHEF_ABI } from "../config/Masterchef";
// import { ERC20_ABI } from "../config/erc20";
// import toast, { Toaster } from 'react-hot-toast';
// import { GetChainIndex, useWeb3 ,usehttpProvider} from "./useWeb3"
// import { CHAINS } from "../config/env";
// import BigNumber from 'bignumber.js';

import { BuyContractABI } from "../config/BuyContractABI"
import  {BUYCONTRACTADDRESS}from '../config/config'
import { UseWeb3 ,useWeb3} from "./useWeb3"

import { getWalletAddress } from "../lib/localStorage"
import isEmpty from "../lib/isEmpty"
import { pid } from "process"

export const UseBuyContract =  async()=>{
    const web3 = await useWeb3();
    try{
        
        const contract  = await new web3.eth.Contract(BuyContractABI,BUYCONTRACTADDRESS);
        console.log(contract,"contract")
        return contract;
    }catch(err){
        console.log(err,"useBuyContract__err")
    }
}


export const BuyBot = async(data)=>{
    const web3 =await useWeb3()
    try{
        console.log(data,"BuyBot",web3)
        // const web3 = await UseWeb3();
        const contract  = await UseBuyContract();
        const account = getWalletAddress();
        const gasPrice = await web3.eth.getGasPrice();
        let encoded = await contract.methods.purchaseBot(
            data.planId.toString(),
            data.periodId.toString(),
            web3.utils.toChecksumAddress(data.tokenAddress),
            web3.utils.toChecksumAddress(data.referrer)
        ).encodeABI();
        let estimatedGasLimit = await web3.eth.estimateGas({
            from:web3.utils.toChecksumAddress(account),
            to: web3.utils.toChecksumAddress(BUYCONTRACTADDRESS),
            data: encoded,
        });
        const Buybot = await contract.methods.purchaseBot(data.planId.toString(),data.periodId.toString(),web3.utils.toChecksumAddress(data.tokenAddress),web3.utils.toChecksumAddress(data.referrer)).send({from:web3.utils.toChecksumAddress(account),gasLimit: parseInt(estimatedGasLimit* 1.5,10),gasPrice:gasPrice})
        return Buybot;
    }catch(err){
        console.log(err,"useBuyContract__err")
    } 
}

export const getTotalPlans =async()=>{
    try{
        const contract  = await UseBuyContract();
        const plans = await contract.methods.getTotalPlan().call()
        console.log(plans,"plans")
        if(plans){
            return plans
        }

    }catch(err){
        console.log(err,"getTotalPlans__err")
    }
}

export const getTotalPeriods  = async()=>{
    try{
        const contract  = await UseBuyContract();
        const compoundingPeriods = await contract.methods.getTotalPeriod().call()
        if(compoundingPeriods){
            return compoundingPeriods
        }

    }catch(err){
        console.log(err,"getTotalPeriods__err")
    }
}
export const getPeriods  = async(pid) =>{
    try{
        const contract  = await UseBuyContract();
        const compoundingPeriods = await contract.methods.getPeriod(pid.toString()).call()
        if(compoundingPeriods){
            return compoundingPeriods
        }

    }catch(err){
        console.log(err,"getTotalPeriods__err")
    }
}
export const getuserPlan =  async(planId,walletaddress)=>{
    // const web3 = await useWeb3();
    try{
        console.log(planId,walletaddress,"getuserPlan")
        const web3 = await UseWeb3();
        const contract  = await UseBuyContract();
        const account = getWalletAddress()
        const userPlan = await contract.methods.getuserPlan(web3.utils.toChecksumAddress(walletaddress),planId.toString()).call({from:walletaddress})
        console.log(userPlan,"getuserPlan")
        if(userPlan){
            return userPlan
        }

    }catch(err){
        console.log(err,"getTotalPeriods__err")
    }
}

export const checkuserPlan = async(plan,walletaddress)=>{
    try{
        console.log(walletaddress,plan,"checkuserPlan")
        if(plan.length > 0 ){
            for(let i=0;i<plan.length;i++){
                let userPlan =  await getuserPlan(i,walletaddress)
                console.log(userPlan._buyTime,"checkuserPlan")
                if(parseFloat(userPlan._buyTime)>0 && plan[i]._isEnable){
                    return true
                }
                if(plan.length-1 && userPlan._buyTime<0){
                    return false
                }
            }
        }
    }catch(err){
        console.log(err,"checkuserPlan_err")
    }
}

export const getAlluserPlan = async(plan,walletaddress)=>{
    try{
        console.log(walletaddress,plan,"checkuserPlan")
        const web3 = await UseWeb3();
        const contract  = await UseBuyContract();
        if(plan.length > 0 ){
            for(let i=0;i<plan.length;i++){
                let userPlan =  await getuserPlan(i,walletaddress)
                let refferAddress  = await contract.methods.parentOf(web3.utils.toChecksumAddress(walletaddress)).call()
                console.log(userPlan._buyTime,"checkuserPlan")
                if(parseFloat(userPlan._buyTime)>0){
                    return userPlan
                }
                // if(plan.length-1 && userPlan._buyTime<0){
                //     return false
                // }
            }
        }
    }catch(err){
        console.log(err,"checkuserPlan_err")
    }
}
export const getAffilateFee = async() =>{
    try{
        const web3 = await UseWeb3();
        const contract  = await UseBuyContract();
        const account = getWalletAddress()
        const fee = await contract.methods.getAffilateFee().call()
        return fee
    }catch(err){
        console.log(err,"getAffilateFee__err")
    }
}

export const getOwner = async()=>{
    try{
        const web3 = await UseWeb3();
        const contract  = await UseBuyContract();
        const account = getWalletAddress()
        const owner = await contract.methods.owner().call()
        return owner
    }catch(err){
        console.log(err,"getAffilateFee__err")
    } 
}

export const getRefferAddress  = async(walletaddress)=>{
    try{
        const web3 = await UseWeb3();
        const contract  = await UseBuyContract();
        let refferAddress  = await contract.methods.parentOf(web3.utils.toChecksumAddress(walletaddress)).call()
        return refferAddress
    }catch(err){
        console.log(err,"refferAddress__err")
    }
}
// export const UseMasterChef = async()=>{
//     try{
//     const web3 = await useWeb3();
//     const contract = new web3.eth.Contract(MASTERCHEF_ABI, getMasterChefAddress());
   
//     return contract;
// }catch (e) {
               
// }  
// }

// export const UsehttpMasterChef = async()=>{
//     try{
//         // console.log("log");
//     const web3 = await usehttpProvider();
//     const contract = new web3.eth.Contract(MASTERCHEF_ABI, getMasterChefAddress());
//     console.log("contract_contractcontractcontract",contract);
   
//     return contract;
// }catch (e) {
               
// }  
// }

// export const UseERC20 = async (VALUE) => {
//     try{
//     const web3 = await useWeb3();
//     // const web3 = await usehttpProvider();
//     const Tokencontract = new web3.eth.Contract(ERC20_ABI, VALUE);
//     return Tokencontract;
// }catch (e) {
               
// }  
// }

// export const UseHttpERC20 = async (VALUE) => {
//     try{
//     const web3 = await usehttpProvider();
//     const Tokencontract = new web3.eth.Contract(ERC20_ABI, VALUE);
//     return Tokencontract;
// }catch (e) {
               
// }  
// }

// export const UseUserBalance = async(TOKEN,account)=>{
//     try{
//     const erc20 = await UseHttpERC20(TOKEN);
//     const balance = await erc20.methods.balanceOf(account).call();
//     return balance;
// }catch (e) {
               
// }
// }


// export const getStakedBalance = async(account)=>{
    
//     // const stake = await UseMasterChef();
//     const stake = await UsehttpMasterChef()

    
// }

export const  sleep = (ms) => 
{ 

    new Promise(resolve => setTimeout(resolve, ms))
}


// export const getNativeToken =()=>{
//     const index = GetChainIndex();
//     console.log("index",index);
//     return CHAINS[index].NATIVE;
// }

// export const getMulticallAddress = ()=>{
//     const index = GetChainIndex();
//     return CHAINS[index].MULTICALL;
// }

// export const getMasterChefAddress = ()=>{
//     try{
//     const index = GetChainIndex();
//     const ContractAddress = CHAINS[index].MASTERCHEF;
 
//     return ContractAddress;
// }catch (e) {
               
// }
// }


// export const checkIsApproved = async (account,token) => {
//     // console.log("checkIsApproved",account,token)
//     try{
//     const erc20contract = await UseHttpERC20(token);
//     const allow = await erc20contract.methods.allowance(account,getMasterChefAddress()).call();
   
//     return (parseInt(allow)>0)?true:false;
// }catch (e) {
               
// }
// }

// export const UseapproveContract = async(account,token)=>{
//     try{
//         const web3 = await useWeb3();
//         // const web3 = new Web3(provider);
//         console.log("web3_pro",web3);
//         const erc20Contract = await UseERC20(token);
//          const data = erc20Contract.methods.approve(getMasterChefAddress(),"115792089237316195423570985008687907853269984665640564039457584007913129639935").send({ from: account})
//         // const encode = erc20Contract.methods.approve(getMasterChefAddress(),"115792089237316195423570985008687907853269984665640564039457584007913129639935").encodeABI()
//         // console.log("encode",encode);
//         // const tx = {
//         //     from: account,
//         //     to: token,
//         //     data: encode,
//         //   };
         
//         //   var gasLimit = await web3.eth.estimateGas(tx);
//         //   tx["gas"] = gasLimit;
//         //   var data = await web3.eth.sendTransaction(tx)
//         await toast.promise(data, {
//          loading: 'Approving...',
//          success: 'Approved Successfully',
//          error: 'Try Again',
//          }, {
//          style: {
//          minWidth: '300px',
//          minHeight: '55px'
//          }
//          })
//     }catch (e) {
//       console.log("approveerror",e);         
//     }
      
   
   
// }

// export const UseTokenInfo = async (TOKEN) => {
//     try{
//     const token = await UseHttpERC20(TOKEN);
//     const name= await token.methods.name().call();
//     const symbol= await token.methods.symbol().call();
//     const decimals= await token.methods.decimals().call();
//     const data = {
//         name: name,
//         symbol: symbol,
//         decimals: decimals,
//         address: TOKEN
//     }
//    console.log("data_data_Vdatav",data);
//     return data;
// }catch (e) {
               
// }
   
   

// }


// export const getUserBalance = async (token,account) => {
//     // console.log("token,account",token,account)
//     try{
//     const tokenContract = await UseHttpERC20(token);
//     const tokenInfo = await UseTokenInfo(token);
//     const balance = await tokenContract.methods.balanceOf(account).call();
//     const balanceTokens = ((new BigNumber(balance)).div(new BigNumber(10).pow(tokenInfo.decimals))).toNumber();
//     // console.log("BALANCETOKENS",balanceTokens );
//     return balanceTokens.toString();
// }catch (e) {
               
// }}

// export const toFixedNumber = (x)=> {
//     if (Math.abs(x) < 1.0) {
//         var e = parseInt(x.toString().split('e-')[1]);
//         if (e) {
//             x *= Math.pow(10, e - 1);
//             x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
//         }
//     } else {
//         var e = parseInt(x.toString().split('+')[1]);
//         if (e > 20) {
//             e -= 20;
//             x /= Math.pow(10, e);
//             x += (new Array(e + 1)).join('0');
//         }
//     }
//     return x;
// }


// export const searchedValue = async(tokenList,searchSymbol) => {
//     // console.log("searchedValue",tokenList,searchSymbol)
//     const filteredData  = await tokenList.filter(value => ((value.lockedPeriod)).includes(searchSymbol));
//     return filteredData;
// }

