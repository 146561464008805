import {
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Button,
  Dropdown,
} from "react-bootstrap";
import { BiSolidUser } from "react-icons/bi";

import { NavLink, Link, useLocation, useHistory } from "react-router-dom";
import { useRef } from "react";
import { logOut } from "../actions/commonAction";
import logo from "../Assets/Bot_logo_snall.png";
import { useEffect, useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoMdClose } from "react-icons/io";
import { useSelector, useDispatch } from "react-redux";
import { disconnectWalletConnect } from "../hooks/useWallet";
function Header({ handleClickFeature, handleClickMeet }) {
  // const [active, setActive] = useState(false);
  // const [header, setHeader] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { isAuth } = useSelector((state) => state.user);

  const SingOut = () => {
    try {

      let wallet = sessionStorage.getItem("wallet")
      if(wallet === "connect"){
        disconnectWalletConnect()
      }

      logOut(dispatch);
      history.push("/");
    } catch (err) {
      console.log(err, "SingOut__err");
    }
  };
  var urlValue = window.location.pathname;

  const location = useLocation();

  const [active, setActive] = useState(false);

  useEffect(() => {
    if (location.pathname != "/") {
      setActive(true);
    } else {
      setActive(false);
    }
    const scrollActive = () => {
      const scrollValue = window.scrollY;
      if (location.pathname === "/") {
        if (scrollValue > "600") {
          setActive(true);
        } else {
          setActive(false);
        }
      } else {
        setActive(true);
      }
    };
    window.addEventListener("scroll", scrollActive);
    return () => window.removeEventListener("scroll", scrollActive);
  }, [location.pathname]);

  const handleDashboard = () => {
    document.getElementsByTagName("body")[0].classList.remove("navbar_true");
    document.getElementsByTagName("body")[0].classList.add("navbar_false");
  };

  console.log("active", active);

  return (
    <Navbar expand="xl" className="bg-body-tertiary d-block d-flex">
      <Container className="position-relative">
        {/* {active && ( */}
        <Navbar.Brand
          href="#home"
          className="logo_position custom_logo_nav position-absolute"
        >
          {" "}
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        </Navbar.Brand>
        {/* )} */}

        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          className="navbar_tog heads_icn_change"
        >
          <span className="custom_hamburger d-flex align-items-center justify-content-center">
            <GiHamburgerMenu className="head_hambgr_menu" />
            <IoMdClose className="head_cls_menu" />
          </span>
        </Navbar.Toggle>

        {/* <Navbar.Collapse id="basic-navbar-nav" className="mob_view ">
          <div className="container d-flex">
            <Nav className="mx-auto align-items-center navbar_sec">

              <NavLink to="/">Home</NavLink>

              <NavLink to="/dashboard" onClick={handleDashboard}>
                Dashboard
              </NavLink>
              <NavLink to="/purchase-bot">Plan</NavLink>
              <NavLink to="/create" className="">
                <Button className="green-btn mob_btn register_custom_btn">
                  Register
                </Button>
              </NavLink>
            </Nav>
          </div>
        </Navbar.Collapse> */}

        {isAuth == true ? (
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="mob_view mob_view_new heads_rgt_side"
          >
            <div className="container d-flex just_cont_end_nav  heads_nvlnk_pads">
              <Nav className="align-items-start align-items-xl-center navbar_sec nav_align_lft">
                <NavLink to="/">Home</NavLink>

                <NavLink to="/dashboard" onClick={handleDashboard}>
                  Dashboard
                </NavLink>
                <NavLink to="/purchase-bot">Plan</NavLink>

                <Dropdown className="ps-xl-3 btn_logout_nr heads_btn_alg-strt">
                  <Dropdown.Toggle className="common_green_Btn user_Logo">
                    <BiSolidUser className="" size={18} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="shadown_drop_menu mt-3 mt-xl-2 heads_drp_dwn_men">
                    <Dropdown.Item
                      href="javascript:void(0)"
                      onClick={() => {
                        SingOut();
                      }}
                    >
                      Sign Out
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Nav>
            </div>
          </Navbar.Collapse>
        ) : (
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="mob_view mob_view_new heads_rgt_side"
          >
            <div className="container d-flex just_cont_end_nav heads_nvlnk_pads">
              <Nav className="align-items-start align-items-xl-center navbar_sec nav_align_lft">
                <NavLink to="/purchase-bot">Plan</NavLink>
                <NavLink to="/create" className="">
                  <Button className="green-btn mob_btn register_custom_btn btn_bg-rmv">
                    Register
                  </Button>
                </NavLink>
              </Nav>
            </div>
          </Navbar.Collapse>
        )}
      </Container>
    </Navbar>
  );
}

export default Header;
