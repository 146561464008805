import React, { useRef, useEffect, useState } from "react";
// import { Switch,BrowserRouter,Redirect, Routes, Route, useLocation } from "react-router-dom";
import { Switch, BrowserRouter,Route ,Redirect} from "react-router-dom"
import "./index.css";
import Home from "./Pages/home";
import PageNotFound from "./components/PageNotFound";
import Header from "./Layouts/header";
import NewHeader from "./Layouts/NewHeader";
import Register from "./Pages/Register";
import "./App.css";
import Login from "./Pages/Login";
import CreateAcc from "./Pages/CreateAcc";
import ConnectWallet from "./Pages/ConnectWallet";
import PurchaseBot from "./Pages/PurchaseBot";
import Dashboard from "./Pages/Dashboard";
import TradeHistory from "./Pages/TradeHistory";
import Notification from "./Pages/Notification";
import EmailVerification from './Pages/EmailVerification';
import { Provider } from "react-redux";
import store from './store';
import HelperRoute from './components/Route/HelperRoute';
import isLogin from './lib/isLogin';
import { decodeJwt } from './actions/jsonWebToken';
import { getAuthToken } from './lib/localStorage';
import { ToastContainer, toast } from 'react-toastify';
// import Context
import SocketContext from './context/SocketContext'

//import component 
import ConditionRoute from './components/Route/conditionRoute';


// import config
import { socket } from './config/socketConnectivity';
import Wallet from "./Pages/Wallet";
import AffiliateProgram from "./Pages/AffiliateProgram";
import Security from "./Pages/Security";
import VideoSec from "./Pages/VideoSec";
export default function App() {


  const { isAuth } = store.getState().user;

  useEffect(() => {
      if (isAuth != true && isLogin()) {
          decodeJwt(getAuthToken(), store.dispatch)
      }
  }, [])

  const feature = useRef(null);
  const meet = useRef(null);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);


  const scrollToFeature = () => {
    if (feature.current) {
      feature.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  const scrollToMeet = () => {
    if (meet.current) {
      meet.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  };



  return (

    <Provider store={store}>
      <BrowserRouter basename="/">
      <SocketContext.Provider value={{ socket }}>
        <Header
          handleClickFeature={scrollToFeature}
          handleClickMeet={scrollToMeet}
        />
          <ToastContainer/>
          <HelperRoute />
        <Switch>

          {/* CMS Page */}
        <ConditionRoute exact path='/' component={Home} type={"public"} />


        <ConditionRoute exact path='/create' component={CreateAcc} type={"public"} />
        <ConditionRoute exact path='/create/:code' component={CreateAcc} type={"public"} />
        <ConditionRoute exact path='/register' component={Register} type={"public"} />
        <ConditionRoute exact path='/login' component={Login} type={"public"} />
       

         {/* PRIVATE */}

        <ConditionRoute exact path='/connect-wallet' component={ConnectWallet} type={"public"} />
        <ConditionRoute exact path='/purchase-bot' component={PurchaseBot} type={"public"} />
        <ConditionRoute exact path='/dashboard' component={Dashboard} type={"private"} />
        <ConditionRoute exact path='/purchase-history' component={TradeHistory} type={"private"} />
        <ConditionRoute exact path='/wallet' component={Wallet} type={"private"} />
        <ConditionRoute exact path='/affiliateprogram' component={AffiliateProgram} type={"private"} />

        
        <ConditionRoute exact path='/security' component={Security} type={"private"} />

        <ConditionRoute exact path='/notification' component={Notification} type={"private"} />
   
          {/* PUBLIC */}

        <ConditionRoute exact path='/email-verification/:authToken' component={EmailVerification} type={"public"} />
        <ConditionRoute exact path='/verify-old-email/:authToken' component={EmailVerification} type={"public"} />
        <ConditionRoute exact path='/verify-new-email/:authToken' component={EmailVerification} type={"public"} />
        
        <ConditionRoute exact path='/video' component={VideoSec} type={"public"} />
        </Switch>
        </SocketContext.Provider>
      </BrowserRouter>
    </Provider>
   
  );
}
















































// import React, { useRef, useEffect, useState } from "react";
// import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
// import "./index.css";
// import Home from "./Pages/home";
// import PageNotFound from "./components/PageNotFound";
// import Header from "./Layouts/header";
// import NewHeader from "./Layouts/NewHeader";
// import Register from "./Pages/Register";
// import "./App.css";
// import Login from "./Pages/Login";
// import CreateAcc from "./Pages/CreateAcc";
// import ConnectWallet from "./Pages/ConnectWallet";
// import PurchaseBot from "./Pages/PurchaseBot";
// import Dashboard from "./Pages/Dashboard";
// import TradeHistory from "./Pages/TradeHistory";
// import EmailVerification from './Pages/EmailVerification';
// import { Provider } from "react-redux";
// import store from './store';
// import HelperRoute from './components/Route/HelperRoute';
// import { ToastContainer, toast } from 'react-toastify';
// // import Context
// import SocketContext from './context/SocketContext'

// //import component 
// import ConditionRoute from './components/Route/conditionRoute';


// // import config
// import { socket } from './config/socketConnectivity';
// import Wallet from "./Pages/Wallet";
// import Security from "./Pages/Security";
// export default function App() {
//   const feature = useRef(null);
//   const meet = useRef(null);

//   useEffect(() => {
//     window.scrollTo({
//       top: 0,
//       behavior: "smooth",
//     });
//   }, []);


//   const scrollToFeature = () => {
//     if (feature.current) {
//       feature.current.scrollIntoView({
//         behavior: "smooth",
//       });
//     }
//   };

//   const scrollToMeet = () => {
//     if (meet.current) {
//       meet.current.scrollIntoView({
//         behavior: "smooth",
//       });
//     }
//   };

//   return (

//     <Provider store={store}>
//       <BrowserRouter basename="/">
//       <SocketContext.Provider value={{ socket }}>
//         <Header
//           handleClickFeature={scrollToFeature}
//           handleClickMeet={scrollToMeet}
//         />
//           <ToastContainer/>
//           <HelperRoute />
//         <Routes>
//           <Route
//             path="/"
//             element={<Home featuresec={feature} meetsec={meet} />}
//           />
//           <Route path="/create" element={<CreateAcc />} />
//           <Route path="/register" element={<Register />} />
//           <Route path="/login" element={<Login />} />
//           <Route path="/connect-wallet" element={<ConnectWallet />} />
//           <Route path="/purchase-bot" element={<PurchaseBot />} />
//           <Route path="/dashboard" element={<Dashboard />} />
//           <Route path="/purchase-history" element={<TradeHistory />} />
//           <Route path="/wallet" element={<Wallet />} />
//           <Route path="/security" element={<Security />} />
//           <Route path='/email-verification/:authToken' element={<EmailVerification />} />
//           <Route path='/verify-old-email/:authToken' element={<EmailVerification />} />
//           <Route path='/verify-new-email/:authToken' element={<EmailVerification />} />
//         </Routes>
//         </SocketContext.Provider>
//       </BrowserRouter>
//     </Provider>
   
//   );
// }
