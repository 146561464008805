import { combineReducers } from "redux";
import account from './accounts';
import wallet from "./walletReducer";
import plan from './plan'
import currency from './currencyReducer'

export default combineReducers({
    user:account,
    wallet:wallet,
    plan:plan,
    currency:currency
});