// import axios from 'axios';
import axios from '../config/axios'
import { key } from '../config/config'
import { jwtDecode } from "jwt-decode";
import {
  WALLET_DATA,
  profile_Data,
  PLANS,
  USER_PLAN,
  NOTICE_DATA,
  CURRENCY_DATA,
} from '../constant'
//import actions
import { decodeJwt } from '../actions/jsonWebToken';
import { setRefferAddress } from '../lib/localStorage';
import isEmpty from '../lib/isEmpty';


export const getSetting =async()=>{
  try{
    var senddata = {
      method: 'get',
      url: `${key.BACK_URL}/api/user/getSettings`,
    }
    let Resp = await axiosFunc(senddata)
    console.log(Resp.data,"getSetting")
    return {
      data :Resp.data.data
    }
  }catch(err){
    console.log('getSetting__errr',err)
  }
}

export const  initpurchaseHistory = async(data)=>{
  try{
    var senddata = {
      method: 'post',
      url: `${key.BACK_URL}/api/user/addpurchaseHistory2`,
      data: data,
    }
    let Resp = await axiosFunc(senddata)
  }catch(err){

  }
}
export const  putpurchaseHistory = async(data)=>{
  try{
    var senddata = {
      method: 'post',
      url: `${key.BACK_URL}/api/user/addpurchaseHistory`,
      data: data,
    }
    let Resp = await axiosFunc(senddata)
  }catch(err){

  }
}
export const getReffer = async(data)=>{
  try{
    var senddata = {
      method: 'get',
      url: `${key.BACK_URL}/api/user/getReffer`,
      params: data,
    }
    let Resp = await axiosFunc(senddata)
    if(Resp.data.status){
      setRefferAddress(Resp.data.refferAddress)
      // localStorage.setItem('refferaddress',Resp.data.refferAddress)
    }
    return Resp.data.status
  }catch(err){
    console.log(err,"getAuthToken__err")
  }
}
export const getAuthToken = async(data,dispatch)=>{
  try{
    var senddata = {
      method: 'get',
      url: `${key.BACK_URL}/api/user/getAuthToken`,
      params: data,
    }
    let Resp = await axiosFunc(senddata)
    decodeJwt(Resp.data.token, dispatch);
    if(!isEmpty(Resp.data.refferaddress)){
      setRefferAddress(Resp.data.refferaddress)
    }
    
    // localStorage.setItem('refferaddress',Resp.data.refferaddress)
    return Resp.data.status
  }catch(err){
    console.log(err,"getAuthToken__err")
  }
}

export const registerfun = async (data, dispatch) => {
  try {
    var senddata = {
      method: 'post',
      url: `${key.BACK_URL}/api/user/register`,
      data: data,
    }
    let Resp = await axiosFunc(senddata)
    // decodeJwt(Resp.data.token, dispatch);
    return Resp.data
  } catch (err) {
    console.log(err, "registerfun___err")
  }
}

export const readNotification = async () => {
  try {
    let respData = await axios({
      'method': 'put',
      url: `${key.BACK_URL}/api/user/read-notification`,
    })
    console.log("respDatarespDatarespData", respData.data);
    return {
      status: respData.data.status,
      loading: false,
      result: respData.data.data
    }
  } catch (err) {
    return {
      status: "failed",
      loading: false,
    }
  }
}

export const getNotification = async (dispatch) => {
  try {
    let respData = await axios({
      'method': 'get',
      url: `${key.BACK_URL}/api/user/get-notification`,
    })
    console.log("respDatare", respData.data.result);
    return {
      status: respData.data.status,
      loading: false,
      ReadMsg: respData.data.result,
    }
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
    }
  }
}
export const readsingelNotification = async (data) => {
  try {
    let respData = await axios({
      'method': 'put',
      url: `${key.BACK_URL}/api/user/readsingel-notification`,
      data
    })
    console.log("jhdfgjhgsdfjgsjh", respData.data.result.unRead);
    return {
      status: respData.data.status,
      loading: false,
      ReadMsg: respData.data.result.all,
      unReadMsg: respData.data.result.unRead,
    }
  } catch (err) {
    return {
      status: "failed",
      loading: false,
    }
  }
}

export const noticedata = (data) => {
  try {
    return {
      type: NOTICE_DATA,
      payload: data
    }
  } catch (err) {
    console.log(err, "noticedata__err")
  }
}
export const profileSettings = async (data) => {

  try {
    const respData = await axios({
      'url': `/api/user/profileSettings`,
      'method': 'post',
      'data': data
    })

    return {
      status: respData.data.status,
      loading: false,
      message: respData.data.message,
      result: respData.data.result
    }
  } catch (err) {
    console.log(err, "profileSettings")
    return {
      status: 'failed',
      loading: false
    }
  }
}
export const axiosFunc = async (data) => {
  try {
    let Resp = await axios(data)
    return Resp
  }
  catch (e) {
    return { success: 'error', msg: null }
  }
}

export const loginfun = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: 'post',
      url: `${key.BACK_URL}/api/user/login`,
      data: data,

    });
    // decodeJwt(respData.data.token, dispatch);
    console.log("sjhdfgsjhdg", respData.data);
    // noticedata(respData.data)
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      walletaddress:respData.data.walletaddress
    };
  } catch (err) {

    return {
      status: "error",
      loading: false,
      message: err.response.data,

    };
  }
}

export const EditEmail = async (data) => {
  console.log("djksfhjhsdjh", data);
  try {
    let respData = await axios({
      method: "post",
      url: `/api/user/emailChange`,
      data,
    });
    console.log("heyyyyyy", respData.data.result);
    return {
      status: respData.data.status,
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "error",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const verifyOldEmail = async (data) => {
  try {
    let respData = await axios({
      method: "put",
      url: `/api/user/emailChange`,
      data,
    });

    return {
      status: respData.data.status,
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "error",
      loading: false,
      message: err.response.data.message,
    };
  }
};

export const setData = (decoded) => {
  return {
    type: profile_Data,
    payload: decoded
  }
}


export const verifyNewEmail = async (data) => {
  try {
    let respData = await axios({
      method: "patch",
      url: `/api/user/emailChange`,
      data,
    });

    return {
      status: respData.data.status,
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      status: "error",
      loading: false,
      message: err.response.data.message,
    };
  }
};
export const userEmailActivation = async (data) => {
  try {
    var senddata = {
      method: 'post',
      url: `${key.BACK_URL}/api/user/confirm-mail`,
      data: data,
    }
    let respData = await axiosFunc(senddata)
    return {
      status: respData.data.status,
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      status: "error",
      loading: false,
      message: err.response.data.message,
    };
  }
}


export const subscribesave = async (data) => {
  try {
    let respData = await axios({
      method: 'post',
      url: `${key.BACK_URL}/api/user/newsletter-add`,
      data: data,

    });
    return {
      status: respData.data.status,
      loading: false,
      message: respData.data.message,
      // result:
    };
  } catch (err) {

    return {
      status: "error",
      loading: false,
      message: err.response.data.message,

    };
  }
}


export const GetCms = async () => {

  try {
    let respData = await axios({
      method: 'get',
      url: `${key.BACK_URL}/api/user/GetCms`,

    });


    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result
    };
  } catch (err) {

    return {
      status: "error",
      loading: false,
      message: err.response.data.message,

    };
  }
}


export const AffiliateData = async () => {

  try {
    let respData = await axios({
      method: 'get',
      url: `${key.BACK_URL}/api/user/AffiliateData`,

    });
    console.log("respDatarespData", respData.data.result);
    return {
      status: respData.data.status,
      loading: false,
      message: respData.data.message,
      result: respData.data.result
    };
  } catch (err) {

    return {
      status: "error",
      loading: false,
      message: err.response.data.message,

    };
  }
}


export const fieldsubmit = async (data,dispatch) => {
  try {
    let respData = await axios({
      method: 'post',
      url: `${key.BACK_URL}/api/user/updatedata`,
      data: data,
    });
    await listprofile(dispatch)
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      status: "error",
      loading: false,
      message: err.response.data.message,
    };
  }
};

export const listprofile = async (dispatch) => {
  try {
    let respData = await axios({
      method: 'get',
      url: `${key.BACK_URL}/api/user/listing-profile`,
    });
    dispatch(setData(respData.data.editData))
    return {
      status: "success",
      loading: false,
      message: respData.data,
    };
  } catch (err) {
    return {
      status: "error",
      loading: false,
      message: err.response.data.message,
    };
  }
}

export const compoundlistingData = async () => {
  try {
    const respData = await axios({
      'url': `/api/user/listing-compound`,
      'method': 'get',

    })
    return {
      status: respData.data.status,
      loading: false,
      message: respData.data.message,
      result: respData.data.data
    }
  } catch (err) {
    console.log(err, "compoundlistingData__err")
    return {
      status: 'failed',
      loading: false
    }
  }

}

export const loginViaWallet = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: 'post',
      url: `${key.BACK_URL}/api/user/loginviawallet`,
      data: data,

    });
    if (respData.data.token) {
      // decodeJwt(respData.data.token, dispatch);
    }
    return {
      status: respData.data.status,
      loading: false,
      message: respData.data,
    };
  } catch (err) {
    return {
      status: "error",
      loading: false,
      message: err.response.data,
    };
  }
}

export const getPlans = async (dispatch, userId) => {
  try {
    let respData = await axios({
      method: 'get',
      url: `${key.BACK_URL}/api/user/getplans`,
      params: { userId: userId }
    });
    dispatch(setPlans(respData.data.result))
    return {
      status: respData.data.status,
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    console.log(err, "getPlans__err")
    return {
      status: "error",
      loading: false,
      message: err?.response?.data,
    };
  }
}

export const setPlans = (data) => {
  try {
    return {
      type: PLANS,
      payload: data
    }
  } catch (err) {
    console.log(err, "setPlans__Err")
  }
}

export const getWallet = async (dispatch) => {
  try {
    let respData = await axios({
      method: 'get',
      url: `${key.BACK_URL}/api/user/getwallet`
    });
    dispatch(setWalletData(respData.data.walletData))
    return {
      status: respData.data.status,
      loading: false,
      result: respData.data.walletData,
    };
  } catch (err) {
    console.log(err, "getPlans__err")
    return {
      status: "error",
      loading: false,
      message: err?.response?.data,
    };
  }
}

export const setWalletData = (data) => {
  try {
    return {
      type: WALLET_DATA,
      payload: data
    }
  } catch (err) {
    console.log(err, "setWalletData__err")
  }
}

export const getUserPlan = async (dispatch) => {
  try {
    let respData = await axios({
      method: 'get',
      url: `${key.BACK_URL}/api/user/getuserPlan`
    });
    dispatch(setUserPlan(respData.data.result))
    return {
      status: respData.data.status,
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    console.log(err, "getPlans__err")
    return {
      status: "error",
      loading: false,
      message: err?.response?.data,
    }
  }
}

export const currencydata = async (dispatch) => {
  // console.log("vatennn",data);
  try {
    let respData = await axios({
      method: 'get',
      url: `${key.BACK_URL}/api/user/currencyData`,
    });
    console.log("respData", respData);
    dispatch(setCurrencyData(respData.data.result))
    return {
      status: "success",
      loading: false,
      message: respData.data,
    };
  } catch (err) {
    return {
      status: "error",
      loading: false,
      message: err.response.data.message,
    };
  }
}


export const unReadNotice = async (dispatch) => {
  // console.log("vatennn",data);
  try {
    let respData = await axios({
      method: 'get',
      url: `${key.BACK_URL}/api/user/unReadNotice`,
    });
    console.log("respDataswwww", respData.data.result);
    dispatch(setunReadNoticeData(respData.data.result))
    return {
      status: "success",
      loading: false,
      message: respData.data.result,
    };
  } catch (err) {
    return {
      status: "error",
      loading: false,
      message: err.response.data.message,
    };
  }
}


export const setUserPlan = (data) => {
  try {
    return {
      type: USER_PLAN,
      payload: data
    }
  } catch (err) {
    console.log(err, "setUserPlan__err")
  }
}

export const setCurrencyData = (data) => {
  try {
    return {
      type: CURRENCY_DATA,
      payload: data
    }
  } catch (err) {
    console.log(err, "setCurrencyData__err")
  }
}


export const setunReadNoticeData = (data) => {
  try {
    return {
      type: NOTICE_DATA,
      payload: data
    }
  } catch (err) {
    console.log(err, "setCurrencyData__err")
  }
}


export const PurcahseBot = async (data) => {
  try {
    let respData = await axios({
      method: 'post',
      url: `${key.BACK_URL}/api/user/purchasePlan`,
      data: data
    });
    console.log("respData", respData);
    return {
      status: respData.data.status,
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    console.log(err, "PurcahseBot___Err")
    return {
      status: "error",
      loading: false,
      message: err.response.data.message,
    };
  }
}

export const getPurcahseDetails = async () => {
  try {
    let respData = await axios({
      method: 'get',
      url: `${key.BACK_URL}/api/user/userpurchasedetails `
    });
    console.log("respData", respData);
    return {
      status: respData.data.status,
      loading: false,
      result: respData.data.PurchaseDetails,
    };
  } catch (err) {
    console.log(err, "getPurcahseDeatils___Err")
    return {
      status: "error",
      loading: false,
      message: err.response.data.message,
    };
  }
}

export const get2FA = async () => {
  try {
    let respData = await axios({
      method: 'get',
      url: `${key.BACK_URL}/api/user/gettwofa`
    });
    console.log("respData", respData);
    return {
      status: respData.data.status,
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    console.log(err, "getPurcahseDeatils___Err")
    return {
      status: "error",
      loading: false,
      message: err.response.data.message,
    };
  }
}

export const Enable2FA = async (data) => {
  try {
    let respData = await axios({
      method: 'post',
      url: `${key.BACK_URL}/api/user/enable2fa`,
      data: data
    });
    console.log("respData", respData);
    return {
      status: respData.data.status,
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    console.log(err, "Enable2FA___Err")
    return {
      status: "error",
      loading: false,
      message: err.response.data.message,
    };
  }
}

export const Disable2FA = async (data) => {
  try {
    let respData = await axios({
      method: 'post',
      url: `${key.BACK_URL}/api/user/disable2fa`,
      data: data
    });
    console.log("respDatasss", respData.data);
    return {
      status: respData.data.status,
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    console.log(err, "Disable2FA___err")
    return {
      status: "error",
      loading: false,
      message: err.response.data.message,
    };
  }
}

export const withDrawRequest = async (data) => {
  try {
    let respData = await axios({
      method: 'post',
      url: `${key.BACK_URL}/api/user/withdraw`,
      data: data
    });
    console.log("respData", respData);
    return {
      status: respData.data.status,
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    console.log(err, "withDrawRequest___err")
    return {
      status: "error",
      loading: false,
      message: err.response.data.message,
    };
  }
}

export const isUserExist  = async(data)=>{
  try{
    let respData = await axios({
      method: 'post',
      url: `${key.BACK_URL}/api/user/isuserexist`,
      data: data
    });
    console.log("respData", respData);
    return {
      status: respData.data.status,
      loading: false,
      isExist: respData.data.data,
    };
  }catch(err){
    console.log(err,"isUserExist__err")
    return {
      status: "error",
      loading: false,
      message: err.response.data.message,
    };
  }
}

export const checkMaxLimit = async()=>{
  try{
    let respData = await axios({
      method: 'get',
      url: `${key.BACK_URL}/api/user/checktotaluser`
    });
    console.log("respData", respData);
    return {
      status: respData.data.status,
      loading: false,
      message: respData.data.message,
    };
  }catch(err){
    console.log(err,"isUserExist__err")
    return {
      status: "error",
      loading: false,
      message: err.response.data.message,
    };
  }
}