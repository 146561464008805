import React, { useState, useEffect } from "react";
import DashboardSidebar from "./DashboardSidebar";
import DashboardHeader from "./DashboardHeader";
import AffiliateContent from "./AffiliateContent";
// import { AffiliateData } from '../api/api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useSelector } from "react-redux";

import copy from "copy-to-clipboard";

import { key } from "../config/config";
function AffiliateProgram() {
  const [sidebar, setSidebar] = useState(false);
  const [affiliatecode, setAffiliatecode] = useState("8uyt76bihidalashihCd");
  // const [width, setWidth] = useState(window.innerWidth);
  const { userDetails } = useSelector((state) => state.user);
  console.log(userDetails,"userDetails")
  const copyToClipboard = (affiliatecode) => {
    // alert(secretData?.secret)
    copy(`${key.FRONT_URL}create/${userDetails.affiliateCode}`);
    toast.success("Affiliate code copied Successfully"
      //  {
      //   style: {
      //     minWidth: '300px',
      //     minHeight: '55px'
      //   }
      // }
    )

  }

  const handleClose = () => {
    setSidebar(!sidebar);
  };

  const updateDimensions = () => {
    // setWidth(window.innerWidth);
    if (window.innerWidth < 767) {
      setSidebar(false);
    } else if (window.innerWidth > 767) {
      setSidebar(true);
    }
  };

  useEffect(() => {
    if (window.innerWidth < 767) {
      setSidebar(false);
    } else if (window.innerWidth > 767) {
      setSidebar(true);
    }
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);



  return (
    <div className="dashboard affiliateprogram">
      <div className="container-fluid d-flex ps-0 ">
        <div className="left_side_sec">
          <DashboardSidebar handleClose={handleClose} sidebar={sidebar} />
        </div>
        <div className="right_side_sec px-4 py-4">
          <DashboardHeader handleClose={handleClose} />
          <div className="card afilaite_card mt-4">
            <div className="card-body">
              <div className="flex_btn_div">
                <div>
                  <p className="aff_link">Affiliate Link</p>
                  <p className="aff_link_bold">{userDetails.affiliateCode}</p>
                </div>
                <div>
                  <button className="copy_icon mt-3 mt-sm-0" onClick={() => copyToClipboard(affiliatecode)}>Copy</button>

                </div>
              </div>


            </div>
          </div>
          <AffiliateContent />
        </div>
      </div>
    </div>
  );
}

export default AffiliateProgram;
