import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { Link, useLocation } from "react-router-dom";
import { useHistory } from "react-router";
import { MdDelete } from "react-icons/md";
import UserLogo from "../Assets/Img/user_logo.png";
import Form from "react-bootstrap/Form";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import BogBot from "../Assets/Img/alien.png";
import { jwtDecode } from "jwt-decode";
// import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { isEmpty } from '../config/common';
import { key } from '../config/config'
import { fieldsubmit, EditEmail } from '../api/api';
import { fileObjectUrl } from '../lib/fileobject'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaRegCopy } from 'react-icons/fa';
import copy from "copy-to-clipboard";
import { toastAlert } from "../lib/toastAlert";
import { useDispatch, useSelector } from "react-redux";

import { getuserPlan } from "../hooks/UseContract";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';


import { getAuthToken, getWalletAddress } from "../lib/localStorage";
function DashboardContent() {
  const dispatch = useDispatch();
  const initialValue = {
    name: "",

  };
  const data = {
    newEmail: ""
  }
  const renderTooltip = (props) => {
    return (
      <Tooltip id="button-tooltip" {...props}>
        <div className="mb-2">
          <span><span className="me-2">Active</span> :
            <span className="ms-2">
              {props && props?.item?._enable == true && props?.item?._isExpriy == false ? <span>Active</span> : <></>}
              {props?.item?._enable == false && props?.item?._isExpriy == false ? <span>Not Active</span> : <></>}
            </span>
          </span>
        </div>

        <div className="mb-2">
          <span><span className="me-2">Expriy</span> :
            <span className="ms-2">
              {props && props?.item?._enable == false && props?.item?._isExpriy == true ? <span>Plan expired</span> : <span>Not expired </span>}
            </span>
          </span>
        </div>

        {/* <div className="mb-2">
          <span><span className="me-2">Duration</span> : <span className="ms-2">{duration}days</span></span>
        </div> */}
      </Tooltip>
    )
  }
  const [show, setShow] = useState(false);
  const [userPlans, setuserPlan] = useState([]);
  const [editEmail, setEditEmail] = useState(false);
  // const [tokendetails, setTokendetails] = useState({});
  const [formValue, setFormValue] = useState(initialValue);
  const [email, setEmail] = useState(data);
  const [validErrors, setValidErrors] = useState({});
  // const [profilelist, setProfilelist] = useState({})
  // const [file, setFiles] = useState("")
  // const [details, setDetails] = useState({})
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleEditEmailShow = () => {
    setEditEmail(true);
  };
  const handleEditEmailClose = () => setEditEmail(false);
  const { userDetails, userPlan } = useSelector((state) => state.user);
  const { walletaddress } = useSelector((state) => state.wallet);

  const { plans, Contractplans } = useSelector((state) => state.plan);

  useEffect(() => {
    if (plans?.length > 0) {
      getUserPlans()
    }
  }, [plans])

  const getUserPlans = async () => {
    try {
      let UserPlan = []
      for (let i = 0; i < plans.length; i++) {
        let account = getWalletAddress()
        let userPlan = await getuserPlan(i, account)
        if (parseFloat(userPlan._buyTime) > 0) {
          UserPlan.push(userPlan)
        }
        if (plans.length - 1 == i) {
          setuserPlan(UserPlan)
        }
      }
    } catch (err) {
      console.log(err, "getUserPlans__err")
    }
  }
  useEffect(() => {
    if (userDetails) {
      setFormValue(userDetails);
    }
  }, [userDetails]);

  const options = {
    loop: true,
    center: true,
    items: 1,
    margin: 0,
    // autoplay: true,
    dots: true,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    nav: userPlan?.purchaseDetails?.length > 1 ? true : false,
    // navigation: true,
    // navigationText: [
    //   "<i class='fa fa-angle-left'></i>",
    //   "<i class='fa fa-angle-right'></i>",
    // ],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  const PurchaseBot = [
    {
      logo: BogBot,
      amount: "20",
      trade: "10-200",
      activate: " Activate Bot",
    },
    {
      logo: BogBot,
      amount: "50",
      trade: "20-500",
      activate: " Activate Bot",
    },
    {
      logo: BogBot,
      amount: "100",
      trade: "20-1000",
      activate: " Activate Bot",
    },
  ];

  const handlechange = (e) => {
    try {
      setValidErrors({});
      const { name, value } = e.target;
      // setProfilelist({ ...profilelist, [name]: value });
      setFormValue({ ...formValue, [name]: value });
    } catch (err) {
      console.log(err, "handlechange");
    }
  };

  const onChangeValue = async (e) => {
    setValidErrors({});
    var { name, value, files } = e.target;
    if (name === "upload") {
      // setFiles(files[0]);
      // setProfilelist({ ...profilelist, ...{ [name]: value } })
      setFormValue({ ...formValue, ...{ profilepic: files[0] } });
    }
  };

  const onChangefile = async (e) => {
    try {
      var { name, value, files } = e.target;
      let validErrors = {};
      if (!/\.(gif|jpeg|tiff|png|webp|bmp|jpg)$/i.test(files[0]?.name)) {
        validErrors.file =
          "Please upload file having extensions  .png,.jpeg,.jpg only.";
      }
      if (Object.keys(validErrors).length > 0) {
        setValidErrors(validErrors);
      } else {
        if (name === "upload") {
          // setFiles(files[0]);
          setFormValue({ ...formValue, ...{ profilepic: files[0] } });
        }
        var passData = new FormData();
        passData.append("file", files[0]);
        var update = await fieldsubmit(passData, dispatch);
        if (update.status == "success") {
          toast.success(update.message);
        }
      }
    } catch (err) {
      console.log(err, "files[0]");
    }
  };

  const validation = async (data) => {
    let validErrors = {};
    var allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
    if (isEmpty(data?.name)) {
      validErrors.name = "name field is required";
    }
    if (typeof data?.profilepic == "object") {
      if (isEmpty(data?.profilepic.name?.match(allowedExtensions))) {
        validErrors.file =
          "Please upload file having extensions  .png,.jpeg,.jpg only.";
      }
    }

    return validErrors;
  };

  const handleSubmit = async () => {
    var value = await validation(formValue);
    if (!isEmpty(value)) {
      setValidErrors(value);
    } else {
      var passData = new FormData();
      passData.append("file", formValue?.profilepic);
      passData.append("name", formValue?.name);
      var adddata = await fieldsubmit(passData, dispatch);
      if (adddata.status == "success") {
        toast.success(adddata.message);
        handleClose();
      }
    }
  };

  const copyToClipboard = () => {
    copy(formValue.walletaddress);
    toast.success("Wallet address copied Successfully", {
      style: {
        minWidth: "300px",
        minHeight: "55px",
      },
    });
  };

  const handleChange = (e) => {
    setValidErrors({})
    e.preventDefault();
    const { name, value } = e.target;
    let formDatas = { ...email, ...{ [name]: value } }
    setEmail(formDatas)

  }


  const validations = async (data) => {
    console.log("datnewEmail", data);
    let validErrors = {};
    let emailRegex = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (isEmpty(data?.newEmail)) {
      validErrors["newEmail"] = "Email field is required";
    }
    else {
      if (typeof data == "object") {
        if (isEmpty(data?.newEmail.match(emailRegex))) {
          validErrors["newEmail"] = "invalid format";
        }
      }
    }
    setValidErrors(validErrors);
    return validErrors;
  }




  const changemailSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log("emailemail", email);
      var values = await validations(email);
      if (!isEmpty(values)) {
        setValidErrors(values);
      } else {
        console.log("enterrrd");
        var reqData = {
          'newEmail': email.newEmail
        }
        console.log("reqData", reqData);
        let { status, loading, error, message, result } = await EditEmail(reqData);
        if (status) {
          toastAlert("success", message);

          setValidErrors({})
          handleEditEmailClose()
        } else {
          if (error) {
            setValidErrors(error);
          } else if (message) {
            toastAlert("error", message);
          }
        }
      }

    }
    catch (err) {
    }
  }
  return (
    <div className="content_section mt-5">
      <p className="profile">Profile</p>
      <div className="row profile-sec">
        <div className="col-xl-4 col-lg-5 col-md-12 col-sm-8 col-md-12 mb-lg-0 mb-xl-0 mb-4">
          <div>
            <Card className="user_profile_card">
              <Card.Body>
                <div className="user_profile">
                  <span className="position-relative">
                    {/* <img src={UserLogo} alt="user_profile" /> */}
                    <img
                      className="img-fluid"
                      height="100"
                      width="100"
                      src={
                        formValue.profilepic == ""
                          ? UserLogo
                          : fileObjectUrl(formValue.profilepic)
                      }
                    />
                    {/* {typeof file == "object" ? <img className="img-fluid" height="100" width="100" src={URL.createObjectURL(file)} /> : <img className="img-fluid" height="100" width="100" src={`${key.BACK_URL}/public/user/${profilelist?.profilepic}`} alt="ggg" />} */}
                  </span>
                </div>
                {/* <Button className="upload_profile mt-3">Upload Profile</Button> */}
                <div class="upload-btn-wrapper mt-3">
                  {/* <button class="btn">Upload Profile</button>
                  <input type="file" name="myfile" /> */}

                  <button class="btn">Upload Profile</button>
                  <input
                    type="file"
                    name="upload"
                    id="upload"
                    onChange={(event) => onChangefile(event)}
                  />
                  <p className="validErrors_style" id="upload-validErrors">
                    {validErrors && validErrors.file}
                  </p>
                </div>
                <div className="user_details mt-4">
                  {/* <div className="d-flex justify-content-between"> */}
                  <div className="row mb-3">
                    <div className="col-12">
                      <p className="user_detail_name">User Name</p>
                    </div>
                    <div className="col-12">
                      <p className="user_detail_op">{formValue.name}</p>
                    </div>
                  </div>
                  {/* </div> */}
                  {/* <div className="d-flex justify-content-between"> */}
                  <div className="row mb-3">
                    <div className="col-12">
                      <p className="user_detail_name">Email Id</p>
                    </div>
                    <div className="col-12">
                      {/* <p className="user_detail_op">{formValue.email}</p> */}
                      <div className="row">
                        <div className="col-10">
                          <p className="user_detail_op">{formValue.email}</p>
                        </div>
                        <div
                          className="col-2 ps-0 edit_icn_btn"
                          onClick={handleEditEmailShow}
                        >
                          <i class="fa-solid fa-pen"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                  {/* <div className="d-flex justify-content-between">
                    <p className="user_detail_name">Reference Id</p>
                    <p className="user_detail_op">HGOJO1466</p>
                  </div> */}
                  {/* <div className="d-flex justify-content-between">
                    <p className="user_detail_name">Password</p>
                    <p className="user_detail_op">**********</p>
                  </div> */}
                  {/* <div className="d-flex justify-content-between"> */}
                  <div className="row ">
                    <div className="col-12">
                      <p className="user_detail_name">Wallet Address</p>
                    </div>
                    <div className="col-12">
                      <p className="user_detail_op">
                        {/* {formValue.walletaddress} */}
                        {/* {formValue.walletaddress !== '' &&
                        <div className='tokens mt-3'>
                          <p>{<>{(formValue?.walletaddress).slice(0, 7)}...{(formValue?.walletaddress).slice((formValue?.walletaddress).length - 9, (formValue?.walletaddress).length - 1)}<FaRegCopy className='ms-2' onClick={copyToClipboard} /></>}</p>
                        </div>
                      } */}

                        {formValue.walletaddress ? (
                          <div className="tokens">
                            <p>
                              {
                                <>
                                  {(formValue?.walletaddress).slice(0, 7)}...
                                  {(formValue?.walletaddress).slice(
                                    (formValue?.walletaddress).length - 9,
                                    (formValue?.walletaddress).length - 1
                                  )}
                                  <FaRegCopy
                                    className="ms-2"
                                    onClick={copyToClipboard}
                                  />
                                </>
                              }
                            </p>
                          </div>
                        ) : (
                          <></>
                        )}
                      </p>
                    </div>
                  </div>
                  {/* </div> */}
                </div>
                <Button
                  variant="primary"
                  type="button"
                  size="lg"
                  className="btn common_green_Btn sign-up-btn btn-block w-100"
                  onClick={handleShow}
                >
                  Edit
                </Button>
              </Card.Body>
            </Card>
          </div>
        </div>
        <div className="col-xl-5 col-xxl-4 offset-xl-1 col-lg-6 offset-lg-1 col-md-8 offset-md-1 col-sm-8 col-12">
          <div className="purchase-bot mt-0 pt-0 w-100">
            {/* {userPlan && userPlan?.purchaseDetails?.length == 1 ?
              userPlan?.purchaseDetails?.map((item, i) => {
                var planData = plans?.find(
                  (vals) => vals?._id == item?.planId
                );
                return (
                  <>
                    <Card
                      className={
                        item?.isExpriy
                          ? "card_custom_sec disabled_card"
                          : "card_custom_sec"
                      }
                    >
                      <Card.Body className="p-0">
                        <div className="p-4">
                          <div className="d-flex justify-content-center">
                            {planData?.image ? (
                              <img
                                src={`${key?.BACK_URL}/public/plan/${planData?.image}`}
                                alt="bigbot"
                                className="img-fluid big-bot"
                              />
                            ) : (
                              <img
                                src={item.logo}
                                alt="bigbot"
                                className="img-fluid big-bot"
                              />
                            )}
                          </div>
                          <div className="d-flex flex-column align-items-center justify-content-center">
                            <p className="amount mb-2">
                              $ {planData?.subscriptionFee}
                            </p>
                            <p className="trade mb-2">
                              (trade with $ {planData?.minLimit}-
                              {planData?.maxLimit})
                            </p>
                          </div>
                        </div>
                        <div className="">
                          <Button className="d-block common_green_Btn  w-100 activate-bot">
                            {planData?.name}
                          </Button>
                        </div>
                      </Card.Body>
                    </Card>
                  </>
                );
              }) : userPlan?.purchaseDetails?.length > 1 ?
                <OwlCarousel
                  id="customer-testimonoals"
                  className="owl-carousel owl-theme"
                  {...options}
                >
                  {userPlan &&
                    userPlan?.purchaseDetails?.map((item, i) => {
                      var planData = plans?.find(
                        (vals) => vals?._id == item?.planId
                      );
                      return (
                        <>
                          <Card
                            className={
                              item?.isExpriy
                                ? "card_custom_sec disabled_card"
                                : "card_custom_sec"
                            }
                          >
                            <Card.Body className="p-0">
                              <div className="p-4">
                                <div className="d-flex justify-content-center">
                                  {planData?.image ? (
                                    <img
                                      src={`${key?.BACK_URL}/public/plan/${planData?.image}`}
                                      alt="bigbot"
                                      className="img-fluid big-bot"
                                    />
                                  ) : (
                                    <img
                                      src={item.logo}
                                      alt="bigbot"
                                      className="img-fluid big-bot"
                                    />
                                  )}
                                </div>
                                <div className="d-flex flex-column align-items-center justify-content-center">
                                  <p className="amount mb-2">
                                    $ {planData?.subscriptionFee}
                                  </p>
                                  <p className="trade mb-2">
                                    (trade with $ {planData?.minLimit}-
                                    {planData?.maxLimit})
                                  </p>
                                </div>
                              </div>
                              <div className="">
                                <Button className="d-block common_green_Btn  w-100 activate-bot">
                                  {planData?.name}
                                </Button>
                              </div>
                            </Card.Body>
                          </Card>
                        </>
                      );
                    })}
                </OwlCarousel> : ""} */}

            {/* {userPlans.length > 0 ? userPlans.map((val, index) => { */}
            {/* return ( */}
            {userPlans?.length > 0 ?
              userPlans?.length == 1 ?
                userPlans?.map((item, i) => {
                    console.log(item, "ShoeDataa")
                    var planData = isEmpty(Contractplans) ? {} : Contractplans[item._planID]
                    let DBPlan = plans?.find((val) => (val.planId == item._planID))
                    planData = { ...planData, ...DBPlan }
                    let ActiveStat = item?._enable && item?._isExpriy ? 'Active' : item?._enable == false && item?._isExpriy == false ? 'In Active' : item?._enable == false && item?._isExpriy == true ? "Expried" : ""

                    if(planData._isEnable){
                      return (
                        <>
                          <Card
                            className={
                              item?.isExpriy
                                ? "card_custom_sec disabled_card"
                                : "card_custom_sec"
                            }
                          >
                            {/* <p className={ActiveStat == 'Active' ? "active_text" : ActiveStat == 'In Active' ? 'active_text inactive' : ActiveStat == 'Expried' ? 'active_text expiry' : ''}>{ActiveStat}</p> */}
  
                            <div className={ActiveStat == 'Active' ? "ribbon activeborder ribbon-top-left" : ActiveStat == 'In Active' ? 'ribbon inactiveborder ribbon-top-left' : ActiveStat == 'Expried' ? 'ribbon expiryborder ribbon-top-left' : ''} ><span className={ActiveStat == 'Active' ? "activeclr" : ActiveStat == 'In Active' ? 'inactiveclr' : ActiveStat == 'Expried' ? 'expiryclr' : ''}>{ActiveStat}</span></div>
  
                            <Card.Body className="p-0">
                              <div className="p-4">
                                <div className="d-flex justify-content-center">
                                  {planData?.image ? (
                                    <img
                                      src={`${key?.BACK_URL}/public/plan/${planData?.image}`}
                                      alt="bigbot"
                                      className="img-fluid big-bot"
                                    />
                                  ) : (
                                    <img
                                      src={BogBot}
                                      alt="bigbot"
                                      className="img-fluid big-bot"
                                    />
                                  )}
                                </div>
                                <div className="d-flex flex-column align-items-center justify-content-center">
                                  <p className="amount mb-2">
                                    $ {parseFloat(planData?._amount) / 10 ** 18}
                                  </p>
                                  <p className="trade mb-2">
                                    (trade with $ {parseFloat(planData?._minIvestment) / 10 ** 18}-
                                    {parseFloat(planData?._maxIvestment) / 10 ** 18})
                                  </p>
                                </div>
                              </div>
                              <div className="">
                                <Button className="d-block common_green_Btn  w-100 activate-bot">
                                  {planData?.name}
                                </Button>
                              </div>
                            </Card.Body>
                          </Card>
                        </>
                      );
                    }
                   
                })
                :
                <OwlCarousel
                  id="customer-testimonoals"
                  className="owl-carousel owl-theme"
                  {...options}
                >
                  {userPlans &&
                    userPlans?.map((item, i) => {
                      console.log(item, "ShoeDataa")
                        var planData = isEmpty(Contractplans) ? {} : Contractplans[item._planID]
                        let DBPlan = plans?.find((val) => (val.planId == item._planID))
                        planData = { ...planData, ...DBPlan }
                        let ActiveStat = item?._enable && item?._isExpriy ? 'Active' : item?._enable == false && item?._isExpriy == false ? 'In Active' : item?._enable == false && item?._isExpriy == true ? "Expried" : ""
                        console.log(ActiveStat,"ActiveStat")
                        // let ActiveStat = 'Expried'
                        if(planData._isEnable){
                          return (
                            <>
                              {/* <OverlayTrigger
                              placement="top"
                              delay={{ show: 250, hide: 400 }}
                              overlay={renderTooltip({item})}
                            > */}
                              <Card
                                className={
                                  item?.isExpriy
                                    ? "card_custom_sec disabled_card"
                                    : "card_custom_sec"
                                }
                              >
                            <div className={ActiveStat == 'Active' ? "ribbon activeborder ribbon-top-left" : ActiveStat == 'In Active' ? 'ribbon inactiveborder ribbon-top-left' : ActiveStat == 'Expried' ? 'ribbon expiryborder ribbon-top-left' : ''} ><span className={ActiveStat == 'Active' ? "activeclr" : ActiveStat == 'In Active' ? 'inactiveclr' : ActiveStat == 'Expried' ? 'expiryclr' : ''}>{ActiveStat}</span></div>

                                {/* <p className={ActiveStat == 'Active' ? "active_text" : ActiveStat == 'In Active' ? 'active_text inactive' : ActiveStat == 'Expried' ? 'active_text expiry' : ''}>{ActiveStat}</p> */}
                                <Card.Body className="p-0">
                                  <div className="p-4">
                                    <div className="d-flex justify-content-center">
                                      {planData?.image ? (
                                        <img
                                          src={`${key?.BACK_URL}/public/plan/${planData?.image}`}
                                          alt="bigbot"
                                          className="img-fluid big-bot"
                                        />
                                      ) : (
                                        <img
                                          src={BogBot}
                                          alt="bigbot"
                                          className="img-fluid big-bot"
                                        />
                                      )}
                                    </div>
                                    <div className="d-flex flex-column align-items-center justify-content-center">
                                      <p className="amount mb-2">
                                        $ {parseFloat(planData?._amount) / 10 ** 18}
                                      </p>
                                      <p className="trade mb-2">
                                        (trade with $ {parseFloat(planData?._minIvestment) / 10 ** 18}-
                                        {parseFloat(planData?._maxIvestment) / 10 ** 18})
                                      </p>
                                    </div>
                                  </div>
                                  <div className="">
                                    <Button className="d-block common_green_Btn  w-100 activate-bot">
                                      {/* {planData?.name} */}
                                    </Button>
                                  </div>
                                </Card.Body>
                              </Card>
                              {/* </OverlayTrigger> */}
                            </>
                          );
                        }
                       
                    })}
                </OwlCarousel> : ""}
            {/* ) */}
            {/* }) : ""} */}
            {/* <OwlCarousel
              id="customer-testimonoals"
              className="owl-carousel owl-theme"
              {...options}
            >
              {userPlan &&
                userPlan?.purchaseDetails?.map((item, i) => {
                  var planData = plans?.find(
                    (vals) => vals?._id == item?.planId
                  );
                  return (
                    <>
                      <Card
                        className={
                          item?.isExpriy
                            ? "card_custom_sec disabled_card"
                            : "card_custom_sec"
                        }
                      >
                        <Card.Body className="p-0">
                          <div className="p-4">
                            <div className="d-flex justify-content-center">
                              {planData?.image ? (
                                <img
                                  src={`${key?.BACK_URL}/public/plan/${planData?.image}`}
                                  alt="bigbot"
                                  className="img-fluid big-bot"
                                />
                              ) : (
                                <img
                                  src={item.logo}
                                  alt="bigbot"
                                  className="img-fluid big-bot"
                                />
                              )}
                            </div>
                            <div className="d-flex flex-column align-items-center justify-content-center">
                              <p className="amount mb-2">
                                $ {planData?.subscriptionFee}
                              </p>
                              <p className="trade mb-2">
                                (trade with $ {planData?.minLimit}-
                                {planData?.maxLimit})
                              </p>
                            </div>
                          </div>
                          <div className="">
                            <Button className="d-block common_green_Btn  w-100 activate-bot">
                              {planData?.name}
                            </Button>
                          </div>
                        </Card.Body>
                      </Card>
                    </>
                  );
                })}
            </OwlCarousel> */}
          </div>
        </div>
      </div>
      <div className="row" style={{ opacity: "0.4", pointerEvents: "none" }}>
        {userPlans && userPlans?.length > 0
          ? userPlans?.map((item) => {
              var planData = isEmpty(Contractplans) ? {} : Contractplans[item._planID]
              let DBPlan = plans?.find((val) => (val.planId == item._planID))
              planData = { ...planData, ...DBPlan }
              // var planData = plans?.find((vals) => vals?._id == item?.planId);
              if(planData._isEnable){
                return (
                  <>
                    <div className="col-xl-4 col-lg-6 col-md-12 col-sm-6 col-12 purchase-bot pt-0 mt-3 mt-sm-4 mt-md-4 mt-lg-5 mt-xl-5">
                      <Card className="card_custom_sec">
                        <Card.Body className="p-0">
                          <div className="p-4">
                            <div className="d-flex align-items-center justify-content-end">
                              <Form.Check className="bot-check" />
                            </div>
                            <div className="d-flex justify-content-center">
                              {planData?.image ? (
                                <img
                                  src={`${key?.BACK_URL}/public/plan/${planData?.image}`}
                                  alt="bigbot"
                                  className="img-fluid big-bot"
                                />
                              ) : (
                                <img
                                  src={BogBot}
                                  alt="bigbot"
                                  className="img-fluid big-bot"
                                />
                              )}
                              {/* <img
                            src={item.logo}
                            alt="bigbot"
                            className="img-fluid big-bot"
                          /> */}
                            </div>
                            <div className="d-flex flex-column align-items-center justify-content-center">
                              <p className="amount mb-2">
                                $ {parseFloat(planData?._amount) / 10 ** 18}
                              </p>
                              <p className="trade mb-2">
                                (trade with $ {parseFloat(planData?._minIvestment) / 10 ** 18}-
                                {parseFloat(planData?._maxIvestment) / 10 ** 18})
                              </p>
                            </div>
                          </div>
                          <div className="">
                            <Button className="d-block common_green_Btn  w-100 activate-bot">
                              {planData.name}
                            </Button>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </>
                );
              }
              
          })
          : ""}
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        centered
        className="dashboard_profile_modal"
      >
        <Modal.Header closeButton className="custom_header_border">
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          {/* <Card className="user_profile_card"> */}
          <Card className="modal_popup_card">
            <Card.Body>
              <div className="user_profile">
                <span className="position-relative">
                  <img
                    className="img-fluid"
                    height="100"
                    width="100"
                    src={
                      formValue.profilepic == ""
                        ? UserLogo
                        : fileObjectUrl(formValue.profilepic)
                    }
                  />
                  {/* {typeof file == "object" ? <img className="img-fluid" height="100" width="100" src={URL.createObjectURL(file)} /> : <img className="img-fluid" height="100" width="100" src={`${key.BACK_URL}/public/user/${profilelist?.profilepic}`} alt="ggg" />} */}
                </span>
              </div>
              <div class="col-md-12 mb-3 upload-btn-wrapper mt-3">
                <button class="btn">Upload Profile</button>
                <input
                  type="file"
                  name="upload"
                  id="upload"
                  onChange={(event) => onChangeValue(event)}
                />
                <p className="validErrors_style" id="upload-validErrors">
                  {validErrors && validErrors.file}
                </p>
              </div>

              <div className="user_details mb-3">
                <div className="row">
                  <div className="col-12 d-flex align-items-center">
                    <p className="mb-0">User Name</p>
                  </div>

                  <div className="col-12 mt-3">
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      id="name"
                      value={formValue?.name}
                      onChange={(e) => handlechange(e)}
                    />
                  </div>
                </div>
                <p className="validErrors_style" id="upload-name">
                  {validErrors && validErrors.name}
                </p>
              </div>
              <div className="d-flex justify-content-end">
                <Button
                  variant="primary"
                  type="button"
                  size="md"
                  className="btn common_green_Btn sign-up-btn btn-block px-3 py-2"
                  // onClick={handleClose}
                  onClick={() => handleSubmit()}
                >
                  Submit
                </Button>
              </div>
            </Card.Body>
          </Card>
          {/* 
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> 
        </Modal.Footer> */}
      </Modal>
      {/* start of edit email modal */}

      <Modal
        show={editEmail}
        onHide={handleEditEmailClose}
        centered
        className="dashboard_profile_modal"
      >
        <Modal.Header closeButton className="custom_header_border">
          <Modal.Title>Change Your Email</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card className="modal_popup_card">
            <Card.Body>
              <div className="user_details mb-3">
                <div className="row">
                  <div className="col-12 col-sm-4 d-flex align-items-center ">
                    <p className="mb-0">New Email :</p>
                  </div>

                  <div className="col-12 col-sm-12 mt-3 mt-sm-3">
                    <input
                      type="text"
                      className="form-control"
                      name="newEmail"
                      id="newEmail"
                      value={formValue?.newEmail}
                      placeholder="Enter your new email"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
                <span className="validErrors_style" id="upload-name">
                  {validErrors && validErrors.newEmail}
                </span>
              </div>
              <div className="d-flex justify-content-end">
                <Button
                  variant="primary"
                  type="button"
                  size="md"
                  className="btn common_green_Btn sign-up-btn btn-block px-3 py-2"
                  // onClick={handleClose}
                  onClick={changemailSubmit}

                >
                  Change Email
                </Button>
              </div>
            </Card.Body>
          </Card>
          {/* 
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> 
        </Modal.Footer> */}
      </Modal>

      {/* end of edit email modal */}
    </div>
  );
}

export default DashboardContent;
