import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useLocation,useHistory } from "react-router-dom";
import { isEmpty } from '../config/common';
import { loginfun ,getAuthToken} from '../api/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {toastAlert} from '../toastalert'
import { useDispatch, useSelector } from 'react-redux'
import { checkuserPlan } from "../hooks/UseContract";

function Login() {
  const dispatch = useDispatch()
  const history = useHistory()
  const { plans ,Contractplans} = useSelector((state) => state.plan);
  useEffect(() => {
    document.getElementsByTagName("body")[0].classList.remove("navbar_false");
    document.getElementsByTagName("body")[0].classList.add("navbar_true");
  }, []);

  const initialValue = {
    email: "",
    password: "",
  };

  const [formValue, setFormValue] = useState(initialValue);
  const [validErrors, setValidErrors] = useState("");
  const [loc, setLoc] = useState("");

  const [loginType, setLoginType] = useState("select");

  const formvalidation = async (data) => {
    var validationErr = {};
    let emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([A-Za-zA-Z\-0-9]+\.)+[A-Za-zA-Z]{2,}))$/;
    if (data.email == "") {
      validationErr.email = "Email cannot be empty";
    } else if (data.email != "") {
      if (!emailRegex.test(data.email)) {
        validationErr.email = "Enter valid email";
      }
    }

    if (!data.password) {
      validationErr.password = "password cannot be empty";
    }
    return validationErr;
  };

  const handleSubmit = async () => {
    var resp = await formvalidation(formValue);
    if (resp) setValidErrors(resp);
    if (!isEmpty(resp)) {
      // console.log("erore", isEmpty(resp))   // shiuld add toastr here
    } else {
      formValue.path = "login";
      const {message,walletaddress,status} = await loginfun(formValue, dispatch);
      if (status) {
        toastAlert("success", message);
        let isValid  =  await checkuserPlan(Contractplans,walletaddress)
        if(isValid){
          await getAuthToken({walletaddress:walletaddress},dispatch)
          history.push("/dashboard")
        }else{
          history.push('/purchase-bot')
        }
      //   setTimeout(() => {
      //     history.push('/dashboard')
      // }, 2000)
        // navigate('/dashboard')
      } else {
        toastAlert("error", message);
      }
    }
  };
  const handlechange = (e) => {
    const { name, value } = e.target;
    setFormValue((formValue) => ({ ...formValue, [name]: value }));
  };

  return (
    <>
      <div className="register-page">
        <div className="container py-5">
          {loginType == "select" ? (
            <>
              <div className="create-acc">
                <div className="container py-5">
                  <div className="row d-flex justify-content-center">
                    <div className="col-xl-4 col-lg-5 col-md-7 col-sm-9 col-9">
                      <div className="">
                        <Button
                          type="button"
                          // size="lg"
                          className="btn common_green_Btn w-100 register-btn"
                          onClick={() => {
                            setLoginType("wallet")
                            history.push('/connect-wallet')
                          }}
                        >
                          Login with wallet
                        </Button>
                        <Button
                          type="button"
                          // size="lg"
                          className="btn common_green_br_Btn w-100 login-btn my-3 my-sm-3 my-md-3 my-lg-5 my-xl-5"
                          onClick={() => {
                            setLoginType("password");
                          }}
                        >
                          Login with password
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : loginType == "password" ? (
            <>
              <h3 className="header text-center py-4">Login</h3>
              <div className="row d-flex justify-content-center">
                <div className="col-xl-6 col-lg-6 col-md-8 col-sm-12 col-12">
                  <Form>
                    <Form.Group className="mb-4" controlId="formBasicEmail">
                      <Form.Label>Email ID</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter email"
                        id="email"
                        name="email"
                        value={formValue.email}
                        onChange={(e) => handlechange(e)}
                      />
                      <p className="text-danger mt-2">{validErrors.email}</p>
                    </Form.Group>
                    <Form.Group className="mb-4" controlId="formBasicPassword">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Password"
                        id="password"
                        name="password"
                        value={formValue.password}
                        onChange={(e) => handlechange(e)}
                      />
                      <p className="text-danger mt-2">{validErrors.password}</p>
                    </Form.Group>
                    {/* <Link to="/dashboard"> */}
                    <Button
                      variant="primary"
                      type="button"
                      size="lg"
                      className="btn common_green_Btn sign-up-btn btn-block w-100"
                      onClick={() => handleSubmit()}
                    >
                      Login
                    </Button>
                    {/* </Link> */}
                  </Form>
                  <p className="account-verify text-center mt-3">
                    Don't have a account <Link to="/register">Register</Link>
                  </p>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}

export default Login;
