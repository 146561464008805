import React, { useEffect, useState, useRef } from "react";
import ReactHtmlParser from "react-html-parser";
import {
  Card,
  Button,
  ProgressBar,
  Dropdown,
  DropdownButton,
  Form,
  InputGroup,
} from "react-bootstrap";
// import { useNavigate } from "react-router";
import { useHistory } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom/cjs/react-router-dom";
import BogBot from "../Assets/Img/alien.png";
import Header from "../Layouts/header";
import bannerimg from "../Assets/bot_img.png";
// import rotimg from "../Assets/Img/Rot.png";
import rotimg from "../Assets/Img/Rot.svg";
import partnerimg from "../Assets/coinbase.png";
import Marquee from "react-fast-marquee";
import rightimg from "../Assets/rightimg.png";
import leftimg from "../Assets/leftimg.png";
import securebot from "../Assets/Img/secure_bot.png";
import Lottie from "lottie-react";
import botjsons from "./bot - 2.json";
import datajsons from "./data1.json";
import cranejsons from "./datajs.json";
// import subscribe from "./botts.json";
import subscribe from "../Assets/news_letter_bot.png";
import bottrade from "./bottrade.json";
import swipeacademy from "../Assets/pdf/swipeacademy.pdf";

import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { AiOutlineArrowRight } from "react-icons/ai";
import Switch from "react-switch";
import bitcoin from "../Assets/bitcoin.png";
import blucoin from "../Assets/blucoin.png";
import alien from "../Assets/alien.png";
import graph from "../Assets/Img/graph.png";
import signup from "../Assets/signup.png";
import money from "../Assets/money-exchange.png";
import robot from "../Assets/robot.png";
import rocket from "../Assets/rocket.png";
import path from "../Assets/path.png";
import trans from "../Assets/trans.png";
import safe from "../Assets/safe.png";
import auto from "../Assets/auto.png";
import lusia from "../Assets/lusia.png";
import maria from "../Assets/maria.png";
import gabrial from "../Assets/gabrial.png";
import gloria from "../Assets/gloria.png";
import barister from "../Assets/barister.png";
import subscribeShape from "../Assets/subscribeshape.svg";
import yellow from "../Assets/Img/yellow.png";
import green from "../Assets/Img/green.png";
import blue from "../Assets/Img/blue.png";
import MeetBotBg from "../Assets/Img/meet_bot.png";
import avatar from "../Assets/avatar.png";
import botLeft from "../Assets/bot_left.png";
import botRight from "../Assets/bot_right.png";

import { BiLogoFacebook } from "react-icons/bi";
import { BiLogoTwitter } from "react-icons/bi";
import { BiLogoInstagram } from "react-icons/bi";
import { BiLogoYoutube } from "react-icons/bi";
import { BiLogoTelegram } from "react-icons/bi";

import { SlArrowDown } from "react-icons/sl";
import LoadingImg from "../Assets/Img/Loading_img.png";

import { useSelector } from "react-redux";

import { key } from "../config/config";

import { AverageReturn } from "../lib/useCalculation";
import { subscribesave, GetCms } from "../api/api";
import { isEmpty } from "../config/common";
import { toastAlert } from "../lib/toastAlert";
import "react-toastify/dist/ReactToastify.css";
import Countdown from "react-countdown";

import { getSetting } from "../api/api";

export default function Home({ featuresec, meetsec }) {
  const [parnetcard, setpartnercard] = useState([
    { id: 1, image: require("../Assets/Group 59963.png") },
    { id: 2, image: require("../Assets/Group 59965.png") },
    { id: 3, image: require("../Assets/Group 59967.png") },
    { id: 4, image: require("../Assets/Group 59969.png") },
    { id: 5, image: require("../Assets/Group 59971.png") },
  ]);

  const [automaticcard, setautomaticcard] = useState([
    { id: 1, amount: "20", desc: "(trade with $10-200)" },
    { id: 2, amount: "50", desc: "(trade with $20-500)" },
    { id: 3, amount: "100", desc: "(trade with $20-1000)" },
    { id: 4, amount: "300", desc: "(trade with $50-2000)" },
    { id: 5, amount: "500", desc: "(trade with $50-4000)" },
    { id: 6, amount: "1000", desc: "(trade with $100-8000)" },
  ]);

  const [formvalue, setFormvalue] = useState({});

  const [cmsdata, setCmsdata] = useState({});

  const [errors, setError] = useState();
  const [volumes, setvolumes] = useState(100);
  const [income, Setincome] = useState(100);
  const [timePeriod, SettimePeriod] = useState("Monthly");
  const [timePeriod1, SettimePeriod1] = useState("Monthly");
  const [Compounding, SetCompounding] = useState("off");
  const [profit, setProfit] = useState(10);
  const [returnProfit, setreturnProfit] = useState(10);

  const [siteSetting,setSiteSetting] = useState({})

  const handleOnChange = (value) => {
    Setincome(value);
    AverageReturns(value);
  };
  const OnChangeIncome = (e) => {
    try {
      const { value, name } = e.target;
      Setincome(value);
      AverageReturns(value);
    } catch (err) {
      console.log(err, "OnChangeIncome__err");
    }
  };
  const [checked, setChecked] = useState(false);

  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
    if (nextChecked) {
      SetCompounding("on");
    } else {
      SetCompounding("off");
    }
  };

  const AverageReturns = (value) => {
    try {
      let Roi = AverageReturn(
        value,
        timePeriod,
        timePeriod1,
        profit,
        Compounding
      );
      setreturnProfit(parseFloat(Roi).toFixed(2));
    } catch (err) {
      console.log(err, "AverageReturn___err");
    }
  };

  const getSiteSetting = async()=>{
    try{
      let {data} = await getSetting()
      if(data){
        console.log('getSiteSetting',data)
        setSiteSetting(data)
      }
    }catch(err){
      console.log(err,"getSiteSetting__err")
    }
  }
  useEffect(() => {
    AOS.init();
    AOS.refresh();
    // console.log("home");
    document.getElementsByTagName("body")[0].classList.remove("navbar_false");
    document.getElementsByTagName("body")[0].classList.add("navbar_true");

    setTimeout(function () {
      document.getElementsByTagName("body")[0].classList.add("loaded");
    }, 3000);


    CmsData();
    getSiteSetting()
  }, []);
  const { plans, Contractplans } = useSelector((state) => state.plan);
  const history = useHistory();

  const changefn = (event) => {
    setError({});
    const name = event.target.name;
    setFormvalue({
      ...formvalue,
      [name]: event.target.value,
    });
  };

  const validation = async () => {
    let errors = {};
    var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
    if (isEmpty(formvalue?.email)) {
      errors.email = "Email field is required";
    } else if (!emailReg.test(formvalue?.email)) {
      errors.email = "Invalid Email";
    }
    return errors;
  };

  const onSubmit = async (event) => {
    var val = await validation();
    if (!isEmpty(val)) {
      setError(val);
    } else {
      const { status, message } = await subscribesave(formvalue);
      if (status) {
        toastAlert("success", message);
        setFormvalue({ email: "" });
      } else {
        toastAlert("error", message);
      }
    }
  };
  const CmsData = async () => {
    const { status, message, result } = await GetCms();
    if (status) {
      // toastAlert('success', message)
      setCmsdata(result);
      // setFormvalue({email:""})
    } else {
      toastAlert("error", message);
    }
  };

  const targetDate = new Date("2024-04-02T00:00:00");

  console.log(siteSetting,"getSiteSetting")

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <div className="count_down_expired">Countdown expired!</div>;
    } else {
      return (
        <>
          <div className="count_down_heading_title">
            Auto Live Arbitrage Trading
          </div>
          <div className="d-flex custom_exp">
            <div>
              {/* <span className="val">{days}</span> */}
              <span className="val">11</span>
              <span className="header">Days</span>
            </div>
            <div>
              <span className="val">{hours}</span>
              <span className="header">Hours</span>
            </div>
            <div>
              <span className="val">{minutes}</span>
              <span className="header">Minutes</span>
            </div>
            <div>
              <span className="val">{seconds}</span>
              <span className="header">Seconds</span>
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <>
      <div id="loader-wrapper">
        <div className="loading_page" id="loader">
          <div className="container animation_div">
            <div className="content_sec text-center p-2 p-sm-3 p-md-3 p-lg-5 p-xl-5">
              <h2 className="mb-3">SWIPE ARBITRAGE</h2>
              <h5 className="mb-3">Technology Innovation Beyond Imagination</h5>
              <img src={LoadingImg} alt="loading" className="img-fluid" />
              <h4 className="mt-2">
                AUTO ROCKET <br></br> DECENTRALIZED <br></br>ARBITRAGE EXCHANGE/
                <br></br>CURRENCIES SWAP<br></br>AND TRADING BOT<br></br>
                (SWIPE CHAIN).
              </h4>
              <h6>LOADING...</h6>
            </div>
          </div>
        </div>

        <div class="loader-section section-left"></div>
        <div class="loader-section section-right"></div>
      </div>

      <div className="homepage homecms_content custom_countdown_timer">
        <div className="count_down">
          {siteSetting?.timerDate && <Countdown
            // date={Date.now() + 20000}
            date={new Date(parseFloat(siteSetting?.timerDate)).getTime()+20000}
            renderer={renderer}
          />}
        </div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div data-aos="fade-up" data-aos-duration="1500">
                <img src={bannerimg} className="img-fluid w-100" alt="banner" />
              </div>
            </div>
            <div className="col-md-6">
              <div data-aos="fade-down" data-aos-duration="1500">
                <h3 className="heading mb-3 mb-sm-3 mb-md-3 mb-lg-5 mb-xl-5">
                  "AUTO ROCKET DECENTRALIZED <br></br>ARBITRAGE CRYPTO
                  COMPOUNDING BOT "{" "}
                  <span className="redclr">SWIPE ARBITRAGE.</span>
                </h3>
                <div>
                  <Button
                    className="btn sandbtn"
                    onClick={() => {
                      history.push("/create");
                    }}
                  >
                    Get Started
                  </Button>
                  {/* <Button className="btn bordersandbtn ms-4"> */}
                  {/* <a className="btn bordersandbtn ms-4"
                      href={swipeacademy} target="_blank" download
                    > */}
                  <Link to="video" className="btn bordersandbtn ms-4">
                    {/* <span className="ps-3"> */}
                    Swipe Academy
                    {/* </span> */}
                  </Link>
                  {/* </a> */}
                  {/* </Button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="partner py-5">
            {cmsdata && cmsdata?.length > 0
              ? cmsdata?.map((data, i) => {
                  return (
                    <>
                      {data?.identifier == "Our_Partners" && (
                        <>
                          {data &&
                            data?.content &&
                            ReactHtmlParser(data?.content)}
                        </>
                      )}
                    </>
                  );
                })
              : ""}
            {/* <h2 className="Our_partner headings text-center mb-3 mb-lg-5">
              Our Partners
            </h2> */}
            <Marquee speed={100} direction="right">
              <div className="partnermarquee mt-2">
                {parnetcard.map((e, i) => (
                  <div className="partnerwidth mx-3 mx-sm-3 mx-md-3 mx-lg-4 mx-lg-4">
                    <img
                      src={e.image}
                      className="img-fluid partnercard"
                      alt="banner"
                    />
                  </div>
                ))}
              </div>
            </Marquee>
          </div>
        </div>
        <div className="container">
          <div className="row py-3 py-sm-3 py-md-3 py-lg-5 py-xl-5 align-items-center">
            <div className="col-md-6">
              <div data-aos="fade-up" data-aos-duration="2500">
                <img
                  src={rotimg}
                  className="img-fluid w-100 compound_img "
                  alt="banner"
                />
              </div>
            </div>
            <div className="col-md-6 mt-4 mt-md-0 ipo_cms_cont">
              {cmsdata && cmsdata?.length > 0
                ? cmsdata?.map((data, i) => {
                    return (
                      <>
                        {data?.identifier == "ipo" && (
                          <>
                            {data &&
                              data?.content &&
                              ReactHtmlParser(data?.content)}
                          </>
                        )}
                      </>
                    );
                  })
                : ""}

              <div className="d-flex justify-content-center justify-content-md-start">
                <Button className="btn sandbtn">Learn More</Button>
              </div>
            </div>
            {/* <div className="col-md-6 mt-4 mt-md-0">
              <h2 className="whtclr headings mb-4">IPO</h2>
              <p className="lite_grey_clr mb-4 fw-300">
                Developing a robust system that can compound profits and
                transfer them automatically to users' wallets to reduce
                emotions and worries.
                <br></br> <br></br>
                Compounding of profits and automatic transfer of profits to
                users' wallets Our initial public offering allowed Us scale a
                sizeable community targeted 5000 initial members only to avoid
                unnecessary panic as a result of traffic congestion.
                <br></br> <br></br>Each IPO account comes with a Cashback in
                terms of our internal tokens known as SWPT- swipe token. Swipe
                Tokens will only be given to 5000 IPO community as shares in
                the company equal to the amount of bot purchased on IPO value
                at 1$ per token internally and $10 per token when listed on
                our exchange for presales and swap
                <br></br> <br></br>IPO allows members to become share and
                account holders on our arbitrage platform enabling them to
                enjoy massive daily ROT during trading contract.
              </p>
              <div className="d-flex justify-content-center justify-content-md-start">
                <Button className="btn sandbtn">Learn More</Button>
              </div>
            </div> */}
          </div>
        </div>

        <div className="container buybot_cms">
          <div className="buytoken py-5">
            <div className="d-flex justify-content-center align-items-center">
              {cmsdata && cmsdata?.length > 0
                ? cmsdata?.map((data, i) => {
                    return (
                      <>
                        {data?.identifier == "buy_bot" && (
                          <>
                            {data &&
                              data?.content &&
                              ReactHtmlParser(data?.content)}
                          </>
                        )}
                      </>
                    );
                  })
                : ""}
              {/* <h2 className="headings titles text-center">
                Buy Bot On IPO to Qualify for trading With Our Auto Compounded
                Bot with 100-1000% Cashback via SWIPE tokens
              </h2> */}
            </div>
          </div>
        </div>

        <div className="auto_tradings_bg py-5 autotrading_cms">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="">
                  {cmsdata && cmsdata?.length > 0
                    ? cmsdata?.map((data, i) => {
                        return (
                          <>
                            {data?.identifier == "bullet_marks" && (
                              <>
                                {data &&
                                  data?.content &&
                                  ReactHtmlParser(data?.content)}
                              </>
                            )}
                          </>
                        );
                      })
                    : ""}
                  {/* <h2 className="d-flex align-items-center auto-trading mb-3 mb-sm-3 mb-md-4 md-lg-4 mb-xl-4">
                    Auto Tradings <span className="dot ms-2"></span>
                  </h2>
                  <h2 className="d-flex align-items-center auto-trading mb-3 mb-sm-3 mb-md-4 md-lg-4 mb-xl-4">
                    Secure <span className="dot ms-2"></span>
                  </h2>
                  <h2 className="d-flex align-items-center auto-trading mb-3 mb-sm-3 mb-md-4 md-lg-4 mb-xl-4">
                    Fast <span className="dot ms-2"></span>
                  </h2>
                  <p className="auto_withdrawal mt-4">
                    Auto withdrawal at the end of each trading contract <br />
                    (you don't need to place and wait for withdrawal).
                  </p> */}
                </div>
              </div>
              <div className="col-md-6 mt-4 mt-md-0 passive_div2">
                <div data-aos="fade-up" data-aos-duration="1500">
                  <img
                    src={securebot}
                    className="img-fluid w-100 secureBot_img"
                    alt="banner"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bot_trades position-relative automatic_bto_xms">
          <div className="bot_left position-absolute">
            <img src={botLeft} alt="left bg" className="img-fluid" />
          </div>
          <div className="bot_right position-absolute">
            <img src={botRight} alt="left bg" className="img-fluid" />
          </div>
          <div className="container">
            <div className="row justify-content-center m-0">
              <div className="col-11 col-md-8 col-lg-7">
                {cmsdata && cmsdata?.length > 0
                  ? cmsdata?.map((data, i) => {
                      return (
                        <>
                          {data?.identifier == "automatic_bot" && (
                            <>
                              {data &&
                                data?.content &&
                                ReactHtmlParser(data?.content)}
                            </>
                          )}
                        </>
                      );
                    })
                  : ""}
                {/* <h2 className="whtclr headings text-center">
                  Automatic Bot Expert Trades
                </h2> */}
                <div className="trade_wraper mt-5">
                  <Lottie
                    animationData={bottrade}
                    className="tradeImg"
                    loop={true}
                  />
                  <div className="row m-0">
                    <div className="col-4 d-flex justify-content-start first_trade_col">
                      <div className="div_center d-flex align-items-center justify-content-center position-relative">
                        <Lottie
                          animationData={datajsons}
                          className="tradeCircleImg position-absolute"
                          loop={true}
                        />
                        <img
                          src={yellow}
                          alt="yellow"
                          className="img-fluid z-2 position-relative img_width"
                        />
                      </div>
                    </div>
                    <div className="col-4 d-flex justify-content-center second_trade_col">
                      <div className="div_center d-flex align-items-center justify-content-center position-relative">
                        <Lottie
                          animationData={datajsons}
                          className="tradeCircleImg position-absolute"
                          loop={true}
                        />
                        <img
                          src={green}
                          alt="green"
                          className="img-fluid z-2 position-relative img_width "
                        />
                      </div>
                    </div>
                    <div className="col-4 d-flex justify-content-end third_trade_col">
                      {" "}
                      <div className="div_center d-flex align-items-center justify-content-center position-relative">
                        <Lottie
                          animationData={datajsons}
                          className="tradeCircleImg position-absolute"
                          loop={true}
                        />
                        <img
                          src={blue}
                          alt="blue"
                          className="img-fluid z-2 position-relative img_width"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-5 mt-lg-5">
                    <div className="col-4 d-flex justify-content-start fisrt_col_div">
                      <div className="profit_wraper d-flex align-items-center justify-content-betweeen gap-3 px-3 py-2">
                        <div className="img_border">
                          <img
                            src={avatar}
                            alt="avatar"
                            className="img-fluid"
                          />
                        </div>
                        <div className="wrapper_content">
                          <p className="profitP whtclr ">John</p>
                          <p className="profit_subP">
                            profit <span className="ms-1">90%</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-4 d-flex justify-content-center second_col_div">
                      {" "}
                      <div className="profit_wraper d-flex align-items-center justify-content-betweeen gap-3 px-3 py-2">
                        <div className="img_border">
                          <img
                            src={avatar}
                            alt="avatar"
                            className="img-fluid"
                          />
                        </div>
                        <div className="wrapper_content">
                          <p className="profitP whtclr ">Smith</p>
                          <p className="profit_subP">
                            profit <span className="ms-1"> 90%</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-4 d-flex justify-content-end third_col_div">
                      {" "}
                      <div className="profit_wraper d-flex align-items-center justify-content-betweeen gap-3 px-3 py-2">
                        <div className="img_border">
                          <img
                            src={avatar}
                            alt="avatar"
                            className="img-fluid"
                          />
                        </div>
                        <div className="wrapper_content">
                          <p className="profitP whtclr ">Williams</p>
                          <p className="profit_subP">
                            profit <span className="ms-1">90%</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container erason_csm_conrt" ref={featuresec}>
          <div className="arbitragebox pt-5 pb-5">
            <div className="d-flex justify-content-center">
              {cmsdata && cmsdata?.length > 0
                ? cmsdata?.map((data, i) => {
                    return (
                      <>
                        {data?.identifier == "3_reasons" && (
                          <>
                            {data &&
                              data?.content &&
                              ReactHtmlParser(data?.content)}
                          </>
                        )}
                      </>
                    );
                  })
                : ""}
              {/* <h2 className="titles text-center mb-3 mb-sm-3 mb-md-3 mb-lg-5 mb-xl-5 headings">
                3 Reasons Why You Should Use <br />
                Swipe Auto Rocket Arbitrage bot
              </h2> */}
            </div>
            <div className="boxes pt-3">
              <div className="row">
                <div className="col-lg-4  text-center">
                  <div className="joins">
                    <Lottie
                      animationData={datajsons}
                      className="spaceimg"
                      loop={true}
                    />
                    <img
                      src={trans}
                      className="img-fluid position-absolute joins_img"
                      alt="banner"
                    />
                  </div>

                  <div className="joins_content">
                    {cmsdata && cmsdata?.length > 0
                      ? cmsdata?.map((data, i) => {
                          return (
                            <>
                              {data?.identifier == "transparent" && (
                                <>
                                  {data &&
                                    data?.content &&
                                    ReactHtmlParser(data?.content)}
                                </>
                              )}
                            </>
                          );
                        })
                      : ""}
                    {/* <h3 className="joins_title">Transparent</h3>
                    <p className="joins_subtitle">
                      Lorem ipsum dolor sit amet, consetetur to the sadipscing
                      elitr, sed diam nonumy eirmod at tempor invidunt ut
                      labore et dolore magna
                    </p> */}
                  </div>
                </div>
                <div className="col-lg-4 mt-5 mt-lg-0 text-center">
                  <div className="joins">
                    <Lottie
                      animationData={datajsons}
                      className="spaceimg"
                      loop={true}
                    />
                    <img
                      src={safe}
                      className="img-fluid position-absolute joins_img"
                      alt="banner"
                    />
                  </div>

                  <div className="joins_content">
                    {cmsdata && cmsdata?.length > 0
                      ? cmsdata?.map((data, i) => {
                          return (
                            <>
                              {data?.identifier == "safe_and_secure" && (
                                <>
                                  {data &&
                                    data?.content &&
                                    ReactHtmlParser(data?.content)}
                                </>
                              )}
                            </>
                          );
                        })
                      : ""}
                    {/* <h3 className="joins_title">safe & secure</h3>
                    <p className="joins_subtitle">
                      Lorem ipsum dolor sit amet, consetetur to the sadipscing
                      elitr, sed diam nonumy eirmod at tempor invidunt ut
                      labore et dolore magna
                    </p> */}
                  </div>
                </div>
                <div className="col-lg-4 mt-5 mt-lg-0   text-center">
                  <div className="joins">
                    <Lottie
                      animationData={datajsons}
                      className="spaceimg"
                      loop={true}
                    />
                    <img
                      src={auto}
                      className="img-fluid position-absolute joins_img"
                      alt="banner"
                    />
                  </div>

                  <div className="joins_content">
                    {cmsdata && cmsdata?.length > 0
                      ? cmsdata?.map((data, i) => {
                          return (
                            <>
                              {data?.identifier == "auto_trade" && (
                                <>
                                  {data &&
                                    data?.content &&
                                    ReactHtmlParser(data?.content)}
                                </>
                              )}
                            </>
                          );
                        })
                      : ""}
                    {/* <h3 className="joins_title">Auto trade</h3>
                    <p className="joins_subtitle">
                      Lorem ipsum dolor sit amet, consetetur to the sadipscing
                      elitr, sed diam nonumy eirmod at tempor invidunt ut
                      labore et dolore magna
                    </p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container profit_cms_cont">
          <div className="simulation pt-5 pb-5">
            {cmsdata && cmsdata?.length > 0
              ? cmsdata?.map((data, i) => {
                  return (
                    <>
                      {data?.identifier == "profit" ? (
                        <>
                          {data &&
                            data?.content &&
                            ReactHtmlParser(data?.content)}
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  );
                })
              : ""}
            {/* <h2 className="headings text-center mb-5">Profit Simulation</h2> */}
            <div className="buytokenbox">
              <img src={bitcoin} className="bitcoin" alt="banner" />
              <img src={blucoin} className="blucoin" alt="banner" />
              <p className="whtclr text-center fw-300 mb-5">
                <BsFillInfoCircleFill className="infoclr" /> Compounding
                <span className="ms-3">
                  <Switch
                    onChange={handleChange}
                    checked={Compounding === "on" ? true : false}
                    uncheckedIcon={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          fontSize: 14,
                          color: "#1dab4b",
                          paddingRight: 2,
                        }}
                      >
                        Off
                      </div>
                    }
                    checkedIcon={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          fontSize: 14,
                          color: "#1dab4b",
                          paddingRight: 0,
                          marginLeft: 3,
                        }}
                      >
                        On
                      </div>
                    }
                    // offColor="#424242"
                    // onColor="#4eab4c"
                    // offHandleColor="#4eab4c"
                    // onHandleColor="#747774"
                    className="react-switch"
                  />
                </span>
              </p>
              <Slider
                min={0}
                max={1000}
                value={income}
                onChange={handleOnChange}
                tooltip={true}
              />
              <div className="row mt-4 align-items-center">
                <div className="col-md-4 mt-4">
                  <p className="mb-0 fw-500 estimate_average">
                    Estimate income
                  </p>
                </div>
                <div className="col-md-8 mt-4 mb-4">
                  <InputGroup className="mb-0">
                    <Form.Control
                      aria-label="Text input with dropdown button"
                      value={income}
                      onChange={OnChangeIncome}
                      placeholder="0"
                    />

                    <Dropdown
                      data-bs-theme="dark"
                      onSelect={(e) => {
                        SettimePeriod(e);
                        let Roi = AverageReturn(
                          income,
                          e,
                          timePeriod1,
                          profit,
                          Compounding
                        );
                        setreturnProfit(parseFloat(Roi).toFixed(2));
                      }}
                    >
                      <Dropdown.Toggle
                        id="dropdown-button-dark-example1"
                        variant="secondary"
                      >
                        {timePeriod}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item eventKey="Monthly">
                          Monthly
                        </Dropdown.Item>
                        <Dropdown.Item eventKey="Yearly">Yearly</Dropdown.Item>
                        <Dropdown.Item eventKey="Weekly">Weekly</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </InputGroup>
                </div>
                <div className="col-md-4 mt-4">
                  <p className="mb-0 fw-500 estimate_average">
                    Average Return %
                  </p>
                </div>
                <div className="col-md-8 mt-4 mb-4">
                  <InputGroup className="mb-0">
                    <Form.Control
                      aria-label="Text input with dropdown button"
                      placeholder="0%"
                      value={returnProfit}
                    />

                    <Dropdown
                      data-bs-theme="dark"
                      onSelect={(e) => {
                        SettimePeriod1(e);
                        let Roi = AverageReturn(
                          income,
                          timePeriod,
                          e,
                          profit,
                          Compounding
                        );
                        setreturnProfit(parseFloat(Roi).toFixed(2));
                      }}
                    >
                      <Dropdown.Toggle
                        id="dropdown-button-dark-example1"
                        variant="secondary"
                      >
                        {timePeriod1}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          eventKey="Monthly"
                          disabled={timePeriod == "Yearly" ? true : false}
                        >
                          Monthly
                        </Dropdown.Item>
                        <Dropdown.Item eventKey="Yearly">Yearly</Dropdown.Item>
                        <Dropdown.Item
                          eventKey="Weekly"
                          disabled={
                            timePeriod == "Monthly" || timePeriod == "Yearly"
                              ? true
                              : false
                          }
                        >
                          Weekly
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </InputGroup>
                </div>
              </div>
              <div></div>
            </div>
          </div>
        </div>
        <div className="container trade_cnmt_cms">
          <div className="automatic py-3 py-sm-3 py-md-4 py-lg-5 py-xl-5">
            {cmsdata && cmsdata?.length > 0
              ? cmsdata?.map((data, i) => {
                  return (
                    <>
                      {data?.identifier == "trade" && (
                        <>
                          {data &&
                            data?.content &&
                            ReactHtmlParser(data?.content)}
                        </>
                      )}
                    </>
                  );
                })
              : ""}
            {/* <h2 className="titles text-center mb-3 headings">
              Trade with our Automatic Rocket Decentralized Arbitrage Crypto
              Bot while you're sleeping with Auto compounding and pack huge
              profit
            </h2> */}
            <div className="row mb-5">
              {Contractplans && Contractplans.length > 0
                ? Contractplans.map((e, i) => {
                    console.log(e, "Contractplans");
                    if (e._isEnable) {
                      let planData = plans?.find((val) => val.planId == i);
                      return (
                        <div className="col-md-6 col-lg-4 mt-5">
                          <div className="card text-center card_hover card_min_max_ht">
                            <div className="card_img_wrapper mx-auto mt-4">
                              {planData?.image ? (
                                <img
                                  src={`${key?.BACK_URL}/public/plan/${planData?.image}`}
                                  class="card-img-top"
                                  alt="banner"
                                />
                              ) : (
                                <img
                                  src={BogBot}
                                  class="card-img-top"
                                  alt="banner"
                                />
                              )}
                            </div>
                            <h3 className="grnclr mb-3 mt-3">
                              $ {parseFloat(e._amount) / 10 ** 18}
                            </h3>
                            {/* <p className="fw-300 mb-2 home-trade-with">{e.desc}</p> */}
                            <p>
                              (trade with $
                              {(parseFloat(e._minIvestment) / 10 ** 18).toFixed(
                                0
                              )}
                              -$
                              {(parseFloat(e._maxIvestment) / 10 ** 18).toFixed(
                                0
                              )}
                              )
                            </p>
                            <div class="card-body cardheight mt-2 card_bt card_txt_clr">
                              <h5 class="card-title mb-0 text-center ">
                                {planData?.name}
                              </h5>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })
                : ""}
            </div>
            <div className="text-center mt-5">
              <Button
                className="btn sandbtn"
                onClick={() => {
                  history.push("/purchase-bot");
                }}
              >
                See More
              </Button>
            </div>
          </div>
        </div>

        <div className="container passin_onc_cms">
          <div className="passive py-3 py-sm-3 py-md-4 py-lg-5 py-xl-5">
            <div className="row align-items-center">
              <div className="col-md-6">
                {cmsdata && cmsdata?.length > 0
                  ? cmsdata?.map((data, i) => {
                      return (
                        <>
                          {data?.identifier == "passive_income" && (
                            <>
                              {data &&
                                data?.content &&
                                ReactHtmlParser(data?.content)}
                            </>
                          )}
                        </>
                      );
                    })
                  : ""}
                {/* <h3 className="mb-4 headings">Earn a Passive Income</h3>
                <p className="mb-4 fw-500 earn_passive_btm_text">
                  Our Auto Rocket Spatial decentralized arbitrage trading bot
                  is designed toprovide seamless integration with multiple
                  DEXs. The bot is programmed to continuously monitor the
                  order books of different DEXs and identify price
                  discrepancies that can be exploited.
                  <br></br> <br></br>
                  Once the price differences are identified, the bot
                  automatically execute trades across different exchanges to
                  take advantage of the price 1discrepancies.
                  <br></br> <br></br>The bot trade 24/7, convert each traded
                  coin to USDT immediately after trading, and repeat the
                  process continuously in mile per second.
                  <br></br> <br></br>Users deposit USDT BEP20 into their
                  decentralized exchanges (DEXs), and the bot buy any coin
                  that presents an arbitrage opportunity. The bot will be
                  connected to users' exchanges via smart contract calls, and
                  users will be able to see their capital asset reflected on
                  their exchanges.
                </p> */}
                <p className="grnclr" style={{ fontWeight: "500" }}>
                  What is Arbitrage ?{" "}
                  <span className="ms-1">
                    <AiOutlineArrowRight />
                  </span>{" "}
                </p>
              </div>
              <div className="col-md-6 passive_div mt-3 mt-sm-3 mt-md-3 mt-lg-0 my-xl-0">
                <div data-aos="fade-up" data-aos-duration="1500">
                  <img
                    src={graph}
                    className="img-fluid w-100 passive_img"
                    alt="banner"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container srrta_arb_cms">
          <div className="arbitrage pt-5 pb-5">
            {/* {cmsdata.length > 0 && cmsdata?.map((data, i) => {
              return (
                 <>
                  {
                    data?.identifier == "start_bot_steps" &&
                    (
                      <>
                        {
                          data && data.content && ReactHtmlParser(
                            data.content
                          )
                        }
                      </>
                    )
                  }
               </>
              )
            })
            } */}
            <h2 className="whtclr titles text-center mb-3 headings">
              Start Arbitrage Bot in 4 Easy Steps
            </h2>
            <p className="our_core_value text-center mb-4 fw-500">
              <span className="our_core_value_bld">"OUR CORE VALUES”</span>{" "}
              Clean conscience, Integrity, Accountability, Transparency
            </p>
            <div className="path mt-5">
              <img
                src={path}
                className="pathimg d-none d-md-block"
                alt="signup"
              />
              <div className="row">
                <div className="col-md-3 mt-2 text-center">
                  <img src={signup} className="img-fluid" alt="signup" />
                  <p className="whtclr mt-3 fw-300">
                    Signup<br></br>on bot
                  </p>
                </div>
                <div className="col-md-3 mt-2 text-center">
                  <img src={money} className="img-fluid" alt="signup" />
                  <p className="whtclr mt-3 fw-300">
                    Connect<br></br>on exchange
                  </p>
                </div>
                <div className="col-md-3 mt-2 text-center">
                  <img src={robot} className="img-fluid" alt="signup" />
                  <p className="whtclr mt-3 fw-300">Choose Bot</p>
                </div>
                <div className="col-md-3 mt-2 text-center">
                  <img src={rocket} className="img-fluid" alt="signup" />
                  <p className="whtclr mt-3 fw-300">
                    Run the bot and<br></br>get profit
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="our_team position-relative pt-5 pb-5  meen_cnt_cms"
          ref={meetsec}
        >
          <div className="container " style={{ height: "100%" }}>
            {cmsdata && cmsdata?.length > 0
              ? cmsdata?.map((data, i) => {
                  return (
                    <>
                      {data?.identifier == "meet" && (
                        <>
                          {data &&
                            data?.content &&
                            ReactHtmlParser(data?.content)}
                        </>
                      )}
                    </>
                  );
                })
              : ""}
            {/* <h2 className="whtclr titles text-center mb-3 tit headings">
              Meet Our Team
            </h2>
            <p className="lite_grey_clr sub_tit text-center mb-4 fw-300">
              Join now to Interact with our Quintessential Founders - Great
              women <br />
              of Africa who are behind this unique project
            </p> */}

            <div className="">
              {" "}
              <div className="position-relative">
                <img
                  src={MeetBotBg}
                  alt="botBg"
                  className="position-absolute meet_bot_bg"
                />
              </div>
              <div className="d-none d-md-block green_leftBot green_clr position-absolute">
                <div className="green_center d-flex align-items-center justify-content-center position-relative">
                  <div class="spinner-wrap">
                    <div class="spinner-item"></div>
                    <div class="spinner-item spinner-item--2"></div>
                    <div class="spinner-item spinner-item--3"></div>
                  </div>
                  <div className="green_circle"></div>
                </div>
              </div>
              <div className="d-none d-md-block green_topRight green_clr position-absolute">
                <div className="green_center d-flex align-items-center justify-content-center position-relative">
                  <div class="spinner-wrap">
                    <div class="spinner-item"></div>
                    <div class="spinner-item spinner-item--2"></div>
                    <div class="spinner-item spinner-item--3"></div>
                  </div>
                  <div className="green_circle"></div>
                </div>
              </div>
              <div className="employee lusia large_screen mt-4 mt-md-0">
                <div className="position-relative align-items-center d-flex new_col">
                  <img src={lusia} alt="gabriel" className="img-fluid" />
                  <div className="hover_container d-none new_col align-items-center position-relative">
                    <div className=" d-flex align-items-center flex-column mob_content_open">
                      <div className="straight_line"></div>
                      <div className="red_circle_anim d-flex align-items-center">
                        <div className="red_circle"></div>
                        <div
                          class="spinner-wrap
                   "
                        >
                          <div class="spinner-item"></div>
                          <div class="spinner-item spinner-item--2"></div>
                          <div class="spinner-item spinner-item--3"></div>
                        </div>
                      </div>
                      <h4
                        className="whtclr mt-3"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Barr. Lusia Shilongo
                      </h4>
                      <p
                        className="green_Clr_txt"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Co-Founder-Namibia
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="employee maria large_screen mt-4 mt-md-0">
                <div className="position-relative align-items-center d-flex new_col">
                  <img src={gloria} alt="gloria" className="img-fluid" />
                  <div className="hover_container d-none new_col align-items-center position-relative">
                    <div className=" d-flex align-items-center flex-column mob_content_open">
                      <div className="straight_line"></div>
                      <div className="red_circle_anim d-flex align-items-center">
                        <div className="red_circle"></div>
                        <div
                          class="spinner-wrap
                   "
                        >
                          <div class="spinner-item"></div>
                          <div class="spinner-item spinner-item--2"></div>
                          <div class="spinner-item spinner-item--3"></div>
                        </div>
                      </div>
                      <h4
                        className="whtclr mt-3"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Gloria Iyke-Onyema
                      </h4>
                      <p
                        className="green_Clr_txt mb-1"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        (ACIB, MNIM)
                      </p>
                      <p
                        className="green_Clr_txt"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Co-Founder/Country Head,Nigeria
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="employee gabrial large_screen mt-4 mt-md-0">
                <div className="position-relative align-items-center d-flex new_col">
                  <img src={gabrial} alt="gabrial" className="img-fluid" />
                  <div className="hover_container d-none new_col align-items-center position-relative">
                    <div className=" d-flex align-items-center flex-column mob_content_open">
                      <div className="straight_line"></div>
                      <div className="red_circle_anim d-flex align-items-center">
                        <div className="red_circle"></div>
                        <div
                          class="spinner-wrap
                   "
                        >
                          <div class="spinner-item"></div>
                          <div class="spinner-item spinner-item--2"></div>
                          <div class="spinner-item spinner-item--3"></div>
                        </div>
                      </div>
                      <h4
                        className="whtclr mt-3"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Itohowo Gabriel
                      </h4>
                      <p
                        className="green_Clr_txt"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Busniess Lead/CEO
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="employee barister large_screen mt-4 mt-md-0">
                <div className="position-relative align-items-center d-flex new_col">
                  <img src={maria} alt="maria" className="img-fluid" />
                  <div className="hover_container d-none new_col align-items-center position-relative">
                    <div className=" d-flex align-items-center flex-column mob_content_open">
                      <div className="straight_line"></div>
                      <div className="red_circle_anim d-flex align-items-center">
                        <div className="red_circle"></div>
                        <div
                          class="spinner-wrap
                   "
                        >
                          <div class="spinner-item"></div>
                          <div class="spinner-item spinner-item--2"></div>
                          <div class="spinner-item spinner-item--3"></div>
                        </div>
                      </div>
                      <h4
                        className="whtclr mt-3"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Mariah Haitembu
                      </h4>
                      <p
                        className="green_Clr_txt"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Lead Namibia
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="employee gloria large_screen mt-4 mt-md-0">
                <div className="position-relative align-items-center d-flex new_col">
                  <img src={barister} alt="barister" className="img-fluid" />
                  <div className="hover_container d-none new_col align-items-center position-relative">
                    <div className=" d-flex align-items-center flex-column mob_content_open">
                      <div className="straight_line"></div>
                      <div className="red_circle_anim d-flex align-items-center">
                        <div className="red_circle"></div>
                        <div
                          class="spinner-wrap
                   "
                        >
                          <div class="spinner-item"></div>
                          <div class="spinner-item spinner-item--2"></div>
                          <div class="spinner-item spinner-item--3"></div>
                        </div>
                      </div>
                      <h4
                        className="whtclr mt-3"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Barrister Onofiok Essien
                      </h4>
                      <p
                        className="green_Clr_txt"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Co-Founder Global Lead/Interior
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="subscribe position-relative subscribe_sec_cms">
          <div className="shape d-none d-lg-block position-absolute">
            <img src={subscribeShape} alt="shape" className="img-fluid" />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-4 d-flex justify-content-center justify-content-lg-start ">
                {" "}
                <img
                  src={subscribe}
                  alt="logo"
                  className="img-fluid news_letter_logo"
                />{" "}
              </div>
              <div className="col-lg-8 d-flex align-items-center justify-content-center justify-content-lg-start">
                <div className="content_wrapper d-flex flex-column gap-3 gap-lg-4">
                  {cmsdata && cmsdata?.length > 0
                    ? cmsdata?.map((data, i) => {
                        return (
                          <>
                            {data?.identifier == "newsletter" && (
                              <>
                                {data &&
                                  data?.content &&
                                  ReactHtmlParser(data?.content)}
                              </>
                            )}
                          </>
                        );
                      })
                    : ""}

                  {/* <h2
                    className="whtclr text-center mt-5 mt-lg-0 heading lets_subscribe"
                    style={{ letterSpacing: "1px" }}
                  >
                    Let's Subscribe the newsletter
                  </h2> */}
                  <input
                    id="email"
                    name="email"
                    type="text"
                    value={formvalue?.email || ""}
                    placeholder="Enter your mail id"
                    className="custom_input p-3 "
                    onChange={(event) => changefn(event)}
                  />
                  <button
                    className="start_btn"
                    id="subscribe"
                    name="subscribe"
                    onClick={(event) => onSubmit(event)}
                  >
                    Subscribe
                  </button>
                </div>
                <br></br>
                <p className="Newsletter-error" style={{ color: "red" }}>
                  {errors && errors?.email}
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <div className="footerr">
          <div className="margin_top">
            <div className="container pt-4 pb-4">
              <div className="row">
                <div className="col d-flex justify-content-lg-end justify-content-center  gap-4">
                  <a href="/" target="_blank">
                    <BiLogoFacebook className="react_icons " />
                  </a>
                  <a href="/" target="_blank">
                    <BiLogoTwitter className="react_icons" />
                  </a>
                  <a href="/" target="_blank">
                    <BiLogoInstagram className="react_icons" />
                  </a>
                  <a href="/" target="_blank">
                    <BiLogoYoutube className="react_icons" />
                  </a>
                  <a href="/" target="_blank">
                    <BiLogoTelegram className="react_icons" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
