import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { Link,useHistory } from "react-router-dom";

import Modal from "react-bootstrap/Modal";
import Card from "react-bootstrap/Card";

import { loginViaWallet,getAuthToken } from "../api/api";
import { checkuserPlan } from "../hooks/UseContract";
import { useSelector,useDispatch } from "react-redux";

//import hooks 
import { MetamaskWallet ,WalletConnectfun} from "../hooks/useWallet";
import MetaMask from "../Assets/Img/MetaMask.png";
import WalletConnect from "../Assets/Img/WalletConnect.png";
import native from "../Assets/Img/native_wallet.png";
import GuidePdf from '../Assets/pdf/SwipeArbitrage_user.pdf'


import { CHAINS } from "../config/config";
function WalletConnectModal(props) {
    const history = useHistory()
  let dispatch = useDispatch()
  // const [walletAddress,setWalletAddress] = useState("")
  const { plans,Contractplans } = useSelector((state) => state.plan);
    const [affiliateModal, setAffiliateModal] = useState(true);
    const OnSetWalletAddress = async(account)=>{
        try{
          let result = await loginViaWallet({walletaddress:account},dispatch)
          if(result.status){
            let isValidUser = await checkuserPlan(Contractplans,account)
            if(isValidUser){
              await getAuthToken({walletaddress:account},dispatch)
              history.push("/dashboard")
            }else{
            //   history.push('/purchase-bot')
            props.onDismiss()
            }
            
          }else{
            history.push("/register")
          }
        }catch(err){
          console.log(err,"OnSetWalletAddress__Err")
        }
      }
      const MetamaskConnectWallet = async () => {
        try {
          const connection = await MetamaskWallet(OnSetWalletAddress,dispatch);
        //   console.log("connect_connect", connection)
        //   if (connection) {
        //     // history.push("/register")
        //   }
        } catch (e) {
          console.log("MetamaskConnectWallet", e)
        }
      }
      const ConnectWalletConnect = async () => {
        try {
          const connection = await WalletConnectfun(OnSetWalletAddress,dispatch);
        //   console.log("connect_connect", connection)
        //   if (connection) {
        //     history.push("/register")
        //   }
        } catch (e) {
          console.log("MetamaskConnectWallet", e)
        }
      }
    // const{walletData}=useSelector((state)=>(state.wallet))
    return (
        <>
            <Modal
                className="purchase_bot_modal"
                centered
                show={affiliateModal}
                onHide={() => { props.onDismiss() }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Wallet Connect</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card className="modal_popup_card">
                        <Card.Body>
                            <div className="connect_wallet pt-2">
                                <div className="container py-5">
                                    <div className="row d-flex justify-content-center">
                                        <div className="col-xl-9 col-lg-9 col-md-10 col-sm-9 col-12">
                                            <h3 className="header text-center">Connect Wallet</h3>
                                            <h4 className="header text-center">Network : {CHAINS[0].NAME}</h4>
                                            <div className="mt-3 w-100">
                                                <Button className="btn btn-block w-100 cmn-btn" onClick={() => { MetamaskConnectWallet() }}>
                                                    <div className="img_sec">
                                                        <img src={MetaMask} alt="Metamask" className="img-fluid" />
                                                        <span className="ps-3">Metamask for Desktop</span>
                                                    </div>
                                                </Button>
                                                {/* <Button className="btn btn-block w-100 cmn-btn" onClick={() => { MetamaskConnectWallet() }}>
                                                    <div className="img_sec">
                                                        <img src={MetaMask} alt="Metamask" className="img-fluid" />
                                                        <span className="ps-3">MetaMask for Mobile</span>
                                                    </div>
                                                </Button> */}
                                                <Button className="btn btn-block w-100 cmn-btn" onClick={() => { MetamaskConnectWallet() }}>
                                                    <div className="img_sec">
                                                        <img src={native} alt="Metamask" className="img-fluid" />
                                                        <span className="ps-3">Native D-app for wallet</span>
                                                    </div>
                                                </Button>
                                                <Button className="btn btn-block w-100 cmn-btn" onClick={() => { ConnectWalletConnect() }}>
                                                    <div className="img_sec">
                                                        <img
                                                            src={WalletConnect}
                                                            alt="wallet connect"
                                                            className="img-fluid"
                                                        />
                                                        <span className="ps-3">Mobile wallet</span>
                                                    </div>
                                                </Button>

                                                <div className="text-center mt-4">
              <a className="green-btn mob_btn register_custom_btn w-100 btn btn-primary py-3 bnt_down" 
              href={GuidePdf} target="_blank" download
              // onClick={()=>{ConnectWalletConnect()}}
              >
                  {/* <img
                    src={WalletConnect}
                    alt="wallet connect"
                    className="img-fluid"
                  /> */}
                  <span className="ps-3">How to connect</span>
                  </a>
              {/* </Link> */}
              </div>
                                                {/* <Link to="/purchase-bot">
                                                    <Button className="btn btn-block w-100 common_green_Btn show-more mt-3">
                                                        Show more
                                                    </Button>
                                                </Link> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default WalletConnectModal;
