import React, { useEffect, useContext, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    listprofile,
    getWallet,
    getPlans,
    getUserPlan,
    currencydata,
    unReadNotice
} from '../../api/api'

//import action 
import {
    ISAUTH,
    WALLET_ADDRESS
} from '../../constant'
import { getAuthToken, getWalletAddress } from '../../lib/localStorage';

import SocketContext from '../../context/SocketContext';

import { PLANS, USER_PLAN, NOTICE_DATA,CONTRACT_PLANS } from '../../constant';

import { createSocketUser } from '../../config/socketConnectivity';

import { getTotalPlans, checkuserPlan, getuserPlan } from '../../hooks/UseContract';
import isEmpty from '../../lib/isEmpty';
import { logOut } from '../../actions/commonAction';

const HelperRoute = () => {
    let dispatch = useDispatch()
    const { isAuth, userDetails } = useSelector((state) => state.user)
    const { walletaddress } = useSelector((state) => state.wallet);
    const socketContext = useContext(SocketContext);
    const { plans, Contractplans } = useSelector((state) => state.plan);
    useEffect(() => {
        if (isAuth) {
            listprofile(dispatch)
            getWallet(dispatch)
            getUserPlan(dispatch)
            currencydata(dispatch)
            unReadNotice(dispatch)
            // createSocketUser(userDetails._id)
        }
    }, [isAuth])

    useEffect(()=>{
        if(Contractplans?.length > 0){
            if(isAuth){
                CheckPlan()
            }
        }
    },[Contractplans,isAuth])

    const CheckPlan  = async()=>{
        try{
            let isValid  =  await checkuserPlan(Contractplans,getWalletAddress())
            if(!isValid){
                console.log('checkuserPlan',isValid)
                logOut(dispatch)
            }
        }catch(err){
            console.log(err,"CheckPlan__err")
        }
    }

    useEffect(() => {
        if (walletaddress) {
            GetPlans(dispatch, walletaddress)
        }
    }, [walletaddress])

    useEffect(() => {
        if (!isEmpty(userDetails)) {
            getPlans(dispatch, userDetails._id)
            createSocketUser(userDetails._id)
        }
    }, [userDetails])

    useEffect(() => {
        getPlans(dispatch, '')
        GetPlans(dispatch, '')
        if (getWalletAddress()) {
            dispatch({
                type: WALLET_ADDRESS,
                payload: getWalletAddress()
            })
        }
        if (getAuthToken()) {
            dispatch({
                type: ISAUTH,
                payload: true
            })
        }
    }, [])

    useEffect(() => {
        socketContext.socket.on('Plan', (result) => {
            try {
                dispatch({
                    type: PLANS,
                    payload: result
                })
            } catch (err) {
                console.log('Plan__err', err)
            }
        })
        socketContext.socket.on('userPlan', (result) => {
            console.log("userPlanss", result)
            try {
                dispatch({
                    type: USER_PLAN,
                    payload: result
                })
            } catch (err) {
                console.log('userPlan__err', err)
            }
        })


        socketContext.socket.on('notice', (result) => {
            console.log("enterddddd");
            console.log("noticessss", result)
            try {
                dispatch({
                    type: NOTICE_DATA,
                    payload: result
                })
            } catch (err) {
                console.log('notice__err', err)
            }
        })
        socketContext.socket.on('userBalance', (result) => {
            try {
                dispatch({
                    type: USER_PLAN,
                    payload: result
                })
            } catch (err) {
                console.log('Plan__err', err)
            }
        })
    }, [socketContext.socket])

    const GetPlans = async (dispatch, walletaddress) => {
        try {
            let data = await getTotalPlans()
            let PlanData = [...data]
            if (!isEmpty(walletaddress)) {
                if (PlanData) {
                    for (let i = 0; i < PlanData.length; i++) {
                        let userPlan = await getuserPlan(i, walletaddress)
                        console.log(userPlan, "isExist")
                        if (userPlan._buyTime > 0) {
                            let obj = { ...PlanData[i] }
                            obj['disable'] = true
                            PlanData[i] = obj
                        }
                        if (PlanData.length - 1 == i) {
                            dispatch({
                                type: CONTRACT_PLANS,
                                payload: PlanData
                            })
                        }
                    }
                }
            } else {
                dispatch({
                    type: CONTRACT_PLANS,
                    payload: PlanData
                })
            }
        } catch (err) {
            console.log(err, "getPlans__Err")
        }
    }
}
export default HelperRoute;